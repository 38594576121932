import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import createRootReducer from "../reducers";
import rootSaga from "../net/sagas";
import { systemCtrl } from "core/middlewares/system_ctrl";

export const history = createBrowserHistory();

export default function configureStore(preloadedState: any) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [routerMiddleware(history), sagaMiddleware, systemCtrl];
  const middlewareEnhancers = applyMiddleware(...middlewares);

  const composedEnhancers = composeWithDevTools(compose(middlewareEnhancers));

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composedEnhancers
  );
  sagaMiddleware.run(rootSaga);

  // Hot reloading
  if ((module as any).hot) {
    (module as any).hot.accept("../reducers", () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
