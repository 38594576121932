import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getAdminLogs(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ADMIN_LOGS, {
      params: params
    });
    yield put({
      type: Actions.ADMIN_LOGS.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ADMIN_LOGS.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAdminLogs() {
  while (true) {
    const action = yield take(Actions.ADMIN_LOGS.GET);
    yield fork(getAdminLogs, action.params);
  }
}

export function* getLogList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.LOG_LIST, { params: params });
    yield put({
      type: Actions.LOG_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.LOG_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLogList() {
  while (true) {
    const action = yield take(Actions.LOG_LIST.GET);
    yield fork(getLogList, action.params);
  }
}

export function* postLogDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.LOG_DELETE, { ...params });
    yield put({
      type: Actions.LOG_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.LOG_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLogDelete() {
  while (true) {
    const action = yield take(Actions.LOG_DELETE.POST);
    yield fork(postLogDelete, action.params);
  }
}
