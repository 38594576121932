import { fork, take, put } from "redux-saga/effects";
import Actions from "../actions";
import ErrorBuilder from "../error_builder";

export function* reloadCourses() {
  try {
    yield put({
      type: Actions.RELOAD_COURSES.RAW,
      response: {}
    });
  } catch (e) {
    yield put({
      type: Actions.RELOAD_COURSES.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchReloadCourses() {
  while (true) {
    yield take(Actions.RELOAD_COURSES.LOCAL);
    yield fork(reloadCourses);
  }
}
