// TODO: 当前因为使用此处变量名来找全路径，所以必须要确定值唯一，需要优化
export enum Pages {
    LOGIN = "login",
    MAIN = "main",
    DASHBOARD = "dashboard",

    USERS = "users",
    USER_DETAIL = "user_detail",
    USER_CREATE = "user_create",

    REPLY_RECORD = "reply_record",
    SUBJECT_RECORD = "subject_record",

    ALL_PRACTISES = "all_practises",
    ALL_PRACTISES_CREATE = "all_practises_create",
    // ALL_PRACTISES_DATEIL = "all_practises_detail",

    PRACTISE_TAGS = "practise_tags",
    PRACTISE_CATEGORIES = "practise_categories",
    PRACTISE_DIFFICULTIES = "practise_difficulties",
    // PRACTISE_EVALUATIONS = "practise_evaluations",

    TAGS_GRADE = "tags_grade",
    TAGS_PROBLEM = "tags_problem",

    TAGS_CLASS = "tags_class",
    // TAGS_CLASS_PUBLIC = "tags_class_public",

    ALL_MELODIES = "all_melodies",
    ALL_MELODIES_CREATE = "all_melodies_create",
    ALL_MELODIES_EDIT = "all_melodies_edit",

    MELODY_GUIDES = "melody_guides",
    MELODY_GUIDES_CREATE = "melody_guides_create",
    MELODY_GUIDES_DETAIL = "melody_guides_detail",

    GUIDE_CATEGORY = "guide_category",

    COURSE_MATERIAL = "course_material",
    COURSE_CHAPTER = "course_chapter",
    COURSE_LESSON = "course_lesson",

    COURSES = "courses",
    SCHEDULES = "schedules",

    ACTIVITIES = "activities",
    // ACTIVITIESADD = "activities_add",
    ACTIVITIESUPDATE = "activities_update",
    // ACTIVITIESEDIT = "activities_edit",

    ADS = "ads",
    // ADS_CREATE = "ads_create",   
    // ADS_DETAIL = "ads_detail",
    ADS_UPDATE = "ads_update",


    ARTICLES = "articles",
    ARTICLES_CLASS = "articles_class",
    // ARTICLE_EDITS = "articles_edit",
    ARTICLES_ADD = "articles_add",
    // ARTICLES_UPDATE = "articles_update",

    FAQS = "faqs",
    // FAQ_CREATE = "faq_create",
    FAQ_EDIT = "faq_edit",




    ADVISES = "advises",
    ADVISES_DETAIL = "advises_detail",

    SOLUTIONS = "solutions",
    // SOLUTION_CREATE = "solution_create",
    // SOLUTION_EDIT = "solution_edit",
    SOLUTIONS_UPDATE = "solution_update",

    NOTIFICATIONS = "notifications",
    // NOTIFIUPDATE = "notifi_update",
    NOTIFIADD = "notifi_add",
    // NOTIFI_EDIT = "notifi_edit",

    VIDEOS = "videos",
    // VIDEOS_DETAIL = "videos_detail",

    OPERATING = "operating",

    AUDIOS = "audios",

    CONFIGURATIONS = "configurations",
    AGREEMENTS = "agreements",
    // AGREEMENTSDETAIL  = "agreement_details",
    // AGREEMENTSADD  = "agreement_add",
    AGREEMENTS_COPY  = "agreement_copy",


    ADMINS = "admins",

    LOGS = 'logs',

    ORGS = 'orgs',
    // ORG_DETAIL = 'org_detail',
    ORG_CREATE = 'org_create',
    // ORG_DETAILS = 'org_details',
    ORG_EDIT = 'org_edit',

    AI = 'ai',
    AI_DETAIL = 'ai_detail',
    AI_CLASS_ADD = 'ai_class_add',
};