import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getSubjectCategoryList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_CATEGORY_LIST, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_CATEGORY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_CATEGORY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectCategoryList() {
  while (true) {
    const action = yield take(Actions.SUBJECT_CATEGORY_LIST.GET);
    yield fork(getSubjectCategoryList, action.params);
  }
}

export function* getSubjectCategoryTagsList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_CATEGORY_TAGSLIST, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_CATEGORY_TAGSLIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_CATEGORY_TAGSLIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectCategoryTagsList() {
  while (true) {
    const action = yield take(Actions.SUBJECT_CATEGORY_TAGSLIST.GET);
    yield fork(getSubjectCategoryTagsList, action.params);
  }
}

export function* getSubjectCategoryDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_CATEGORY_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_CATEGORY_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_CATEGORY_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectCategoryDetail() {
  while (true) {
    const action = yield take(Actions.SUBJECT_CATEGORY_DETAIL.GET);
    yield fork(getSubjectCategoryDetail, action.params);
  }
}

export function* postSubjectCategoryUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_CATEGORY_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_CATEGORY_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_CATEGORY_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectCategoryUpdate() {
  while (true) {
    const action = yield take(Actions.SUBJECT_CATEGORY_UPDATE.POST);
    yield fork(postSubjectCategoryUpdate, action.params);
  }
}

export function* postSubjectCategorySave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_CATEGORY_SAVE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_CATEGORY_SAVE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_CATEGORY_SAVE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectCategorySave() {
  while (true) {
    const action = yield take(Actions.SUBJECT_CATEGORY_SAVE.POST);
    yield fork(postSubjectCategorySave, action.params);
  }
}

export function* postSubjectCategoryDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_CATEGORY_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_CATEGORY_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_CATEGORY_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectCategoryDelete() {
  while (true) {
    const action = yield take(Actions.SUBJECT_CATEGORY_DELETE.POST);
    yield fork(postSubjectCategoryDelete, action.params);
  }
}
