const data = [
  {
    key: "users", //用户管理
    value: [
      "users" //所有用户
    ]
  },
  {
    key: "course_manage", //课程管理
    value: [
      "schedules", //排课管理
      "courses" //课程信息
    ]
  },
  {
    key: "practises", //练习题
    value: [
      "all_practises", //全部练习题
      "practise_difficulties", //难度管理
      "practise_categories" //分类管理
    ]
  },
  {
    key: "tags", //标签管理
    value: [
      "practise_tags", //标签库
      "tags_class", //分类管理
      "tags_grade", //难度等级
      "tags_problem" //课程问题
    ]
  },
  {
    key: "melodies", //曲谱管理
    value: [
      "all_melodies", //全部曲谱
      "melody_guides", //引导库
      "guide_category", //引导分类
      "course_material" //课程教材
    ]
  },
  {
    key: "articles", //文章管理
    value: [
      "articles_class", //分类管理
      "articles" //文章管理
    ]
  },
  {
    key: "system", //系统管理
    value: [
      "configurations", //全部配置
      "agreements", //平台协议
      "logs", //日志管理
      "operating", //操作日志
      "admins" //全部管理员
    ]
  }
];

function format_menu(value: string) {
  const result: string[] = [];
  data.forEach(items => {
    for (let item of items.value) {
      if (item === value) {
        result.push(items.key);
      }
    }
  });
  return result;
}

export default format_menu;
