import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getFaqList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.FAQ_LIST, { params: params });
    yield put({
      type: Actions.FAQ_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.FAQ_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchFaqList() {
  while (true) {
    const action = yield take(Actions.FAQ_LIST.GET);
    yield fork(getFaqList, action.params);
  }
}

export function* getFaqDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.FAQ_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.FAQ_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.FAQ_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchFaqDetail() {
  while (true) {
    const action = yield take(Actions.FAQ_DETAIL.GET);
    yield fork(getFaqDetail, action.params);
  }
}

export function* postFaqAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.FAQ_ADD, { ...params });
    yield put({
      type: Actions.FAQ_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.FAQ_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchFaqAdd() {
  while (true) {
    const action = yield take(Actions.FAQ_ADD.POST);
    yield fork(postFaqAdd, action.params);
  }
}

export function* postFaqUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.FAQ_UPDATE, { ...params });
    yield put({
      type: Actions.FAQ_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.FAQ_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchFaqUpdate() {
  while (true) {
    const action = yield take(Actions.FAQ_UPDATE.POST);
    yield fork(postFaqUpdate, action.params);
  }
}

export function* postFaqDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.FAQ_DELETE, { ...params });
    yield put({
      type: Actions.FAQ_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.FAQ_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchFaqDelete() {
  while (true) {
    const action = yield take(Actions.FAQ_DELETE.POST);
    yield fork(postFaqDelete, action.params);
  }
}
