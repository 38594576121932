import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getTextBookList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TEXTBOOK_LIST, {
      params: params
    });
    yield put({
      type: Actions.TEXTBOOK_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTextBookList() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_LIST.GET);
    yield fork(getTextBookList, action.params);
  }
}

export function* postTextBookAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_ADD, { ...params });
    yield put({
      type: Actions.TEXTBOOK_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookAdd() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_ADD.POST);
    yield fork(postTextBookAdd, action.params);
  }
}

export function* postTextBookUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookUpdate() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_UPDATE.POST);
    yield fork(postTextBookUpdate, action.params);
  }
}

export function* postTextBookDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookDelete() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_DELETE.POST);
    yield fork(postTextBookDelete, action.params);
  }
}

export function* getTextBookChapterList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TEXTBOOK_CHAPTER_LIST, {
      params: params
    });
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTextBookChapterList() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_CHAPTER_LIST.GET);
    yield fork(getTextBookChapterList, action.params);
  }
}

export function* postTextBookChapterAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_CHAPTER_ADD, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookChapterAdd() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_CHAPTER_ADD.POST);
    yield fork(postTextBookChapterAdd, action.params);
  }
}

export function* postTextBookChapterUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_CHAPTER_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookChapterUpdate() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_CHAPTER_UPDATE.POST);
    yield fork(postTextBookChapterUpdate, action.params);
  }
}

export function* postTextBookChapterDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_CHAPTER_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_CHAPTER_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookChapterDelete() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_CHAPTER_DELETE.POST);
    yield fork(postTextBookChapterDelete, action.params);
  }
}

export function* getTextBookLessonList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TEXTBOOK_LESSON_LIST, {
      params: params
    });
    yield put({
      type: Actions.TEXTBOOK_LESSON_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_LESSON_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTextBookLessonList() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_LESSON_LIST.GET);
    yield fork(getTextBookLessonList, action.params);
  }
}

export function* postTextBookLessonAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_LESSON_ADD, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_LESSON_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_LESSON_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookLessonAdd() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_LESSON_ADD.POST);
    yield fork(postTextBookLessonAdd, action.params);
  }
}

export function* postTextBookLessonUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_LESSON_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_LESSON_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_LESSON_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookLessonUpdate() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_LESSON_UPDATE.POST);
    yield fork(postTextBookLessonUpdate, action.params);
  }
}

export function* postTextBookLessonDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TEXTBOOK_LESSON_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TEXTBOOK_LESSON_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TEXTBOOK_LESSON_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTextBookLessonDelete() {
  while (true) {
    const action = yield take(Actions.TEXTBOOK_LESSON_DELETE.POST);
    yield fork(postTextBookLessonDelete, action.params);
  }
}
