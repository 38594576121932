import * as React from 'react';
import View from 'core/components/View';
import { IStyleProps } from 'core/interfaces/icommon_props';
import { Dropdown, Menu, Avatar, Icon, Button } from 'antd';
import AppGlobal from 'app_global';
import { bindActionCreators } from 'redux';
import { withRouter, connect } from "core";

interface INavrops extends IStyleProps {
  onloginOut?: () => void,
  onUserInfo?: () => void,
}

function mapStateToProps(state: any) {
  return {
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
  }, dispatch);
}

@withRouter()
@connect(mapStateToProps, mapDispatchToProps)
class NavRight extends View<INavrops, {}> {

  private menu = (
    <Menu
      onClick={(item) => {
        console.log(item.key);
        if (item.key === "userInfo") {
          if (this.props.onUserInfo) this.props.onUserInfo();
        } else if (item.key === "loginOut") {
          if (this.props.onloginOut) this.props.onloginOut();
        }
      }}
    >
      <Menu.Item
        key="userInfo"
      >
        <span>个人资料</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="loginOut"
      >
        <span>退出登录</span>
      </Menu.Item>
    </Menu>
  )


  public render() {
    return (
      <div
        style={{
          ...this.props.style,
        }}
      >
        <div
          style={{ width: "1px", height: "130%", backgroundColor: "rgba(0,0,0,0.1)", marginRight: "20px" }}
        >
        </div>
        <div
          style={{ backgroundColor: "#F8C84C" }}
        >
          <Dropdown overlay={this.menu}>
            <Button
              style={{
                color: "#333333",
                backgroundColor: "rgba(0,0,0,0)"
              }}
              type="link"
            >
              <Avatar icon="user" style={{ marginRight: "13px" }} src={AppGlobal.adminData ? AppGlobal.adminData.avatar : ""}></Avatar>
              {AppGlobal.adminData ? AppGlobal.adminData.realname : ""}
              <Icon type="down" style={{ marginLeft: "13px" }} />
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default NavRight;