enum API {
    SERVER_TIME                                     = "/server_time",
    LOGIN                                           = "/login",

    USER_LIST                                       = "/user/list",
    USER_DETAIL                                     = "/user/detail",
    USER_UPDATE                                     = "/user/update",
    USER_GEN_STUDENT                                = "/user/gen/student",
    USER_GEN_TEACHER                                = "/user/gen/teacher",
    USER_DELETE                                     = "/user/delete",
    USER_CREATE                                     = "/user/create",
    USER_MAIN_TEACHER_BATCH_BIND                    = "/user/main_teacher/batch_bind",
    USER_HOMEWORK_LIST                              = "/user/homework/list",
    USER_HOMEWORK_STEPS                             = "/user/homework/step/list",
    USER_HOMEWORK_SUBJECTS                          = "/user/homework/subjects",
    HOMEWORK_REVIEW_DELETE                          = "/user/homework/step/review/delete",
    HOMEWORK_REVIEW_SUBMIT                          = "/user/homework/step/review/submit",
    HOMEWORK_REVIEW_LIST                            = "/user/homework/step/review/list",
    USER_HOMEWORK_ANSWER                            = "/user/homework/answer",

    //修改用户组件
    USER_UPDATE_USER_COMPONENT                      = "/user/update_user_component",
    USER_USER_COMPONENT                             = "/user/user_component",                                             
    COMPONENT_LIST                                  = "/component/list", 

    ADD_SCHEDULE                                    = "/schedule/add",
    UPDATE_SCHEDULE                                 = "/schedule/update",
    SCHEDULE_LIST                                   = "/schedule/list",
    SCHEDULE_DELETE                                 = "/schedule/delete",

    COURSES                                         = "/schedule/course/list",
    ADJUST_COURSE                                   = "/schedule/course/adjust",
    ABSENT_LIST                                     = "/schedule/course/absent",
    MANUAL_FINISH                                   = "/schedule/course/finish",
    SCHEDULE_SELECT                                 = "schedule/course/select",
    SCHEDULE_COURSE_AI_COURSE                       = "/schedule/course/ai_course",

    USER_SCHEDULE_LIST                              = "/user/schedule/list",

    ADMIN_LIST                                      = "/system/admin/list",
    ADMIN_ADD                                       = "/system/admin/add",
    ADMIN_UPDATE                                    = "/system/admin/update",
    ADMIN_DELETE                                    = "/system/admin/delete",
    ADMIN_LOGS                                      = "/system/admin/logs",

    LOG_LIST                                        = "/system/log/list",
    LOG_DELETE                                      = "/system/log/delete",




    ORG_LIST                                        = "/org/list",
    ORG_DETAIL                                      = "/org/detail",
    ORG_CREATE                                      = "/org/create",
    ORG_UPDATE                                      = "/org/update",
    ORG_DELETE                                      = "/org/delete",
    ORG_SETTING_DETAIL                              = "/org/setting/detail",
    ORG_SETTING_UPDATE                              = "/org/setting/update",


    GUIDE_LIST                                      = "/guide/list",
    GUIDE_ADD                                       = "/guide/add",
    GUIDE_UPDATE                                    = "/guide/update",
    GUIDE_DELETE                                    = "/guide/delete",
    GUIDE_DETAIL                                    = "/guide/detail",



    GUIDE_CATEGORY_LIST                             = "/guide/category/list",
    GUIDE_CATEGORY_ADD                              = "/guide/category/add",
    GUIDE_CATEGORY_UPDATE                           = "/guide/category/update",
    GUIDE_CATEGORY_DELETE                           = "/guide/category/delete",

    MELODY_GUIDE_DETAIL                             = "/melody/guide/detail",
    MELODY_GUIDE_DELETE                             = "/melody/guide/delete",
    MELODY_GUIDE_ADD                                = "/melody/guide/add",
    MELODY_GUIDE_UPDATE                             = "/melody/guide/update",
    


    MELODY_LIST                                     = "/melody/list",
    MELODY_ADD                                      = "/melody/add",
    MELODY_UPDATE                                   = "/melody/update",
    MELODY_DELETE                                   = "/melody/delete",
    MELODY_DETAIL                                   = "/melody/detail",
    MELODY_IMAGE_DELETE                             = "/melody/image/delete",
    MELODY_IMAGE_UPDATE                             = "/melody/image/update",
    MELODY_IMAGE_ADD                                = "/melody/image/add",



    TEXTBOOK_LIST                                   = "/textbook/list",
    TEXTBOOK_ADD                                    = "/textbook/add",
    TEXTBOOK_UPDATE                                 = "/textbook/update",
    TEXTBOOK_DELETE                                 = "/textbook/delete",

    TEXTBOOK_CHAPTER_LIST                           = "/textbook/chapter/list",
    TEXTBOOK_CHAPTER_ADD                            = "/textbook/chapter/add",
    TEXTBOOK_CHAPTER_UPDATE                         = "/textbook/chapter/update",
    TEXTBOOK_CHAPTER_DELETE                         = "/textbook/chapter/delete",

    TEXTBOOK_LESSON_LIST                            = "/textbook/lesson/list",
    TEXTBOOK_LESSON_ADD                             = "/textbook/lesson/add",
    TEXTBOOK_LESSON_UPDATE                          = "/textbook/lesson/update",
    TEXTBOOK_LESSON_DELETE                          = "/textbook/lesson/delete",

    SUBJECT_LIST                                    = "/subject/list",
    SUBJECT_DETAIL                                  = "/subject/detail",
    SUBJECT_SAVE                                    = "/subject/save",
    SUBJECT_UPDATE                                  = "/subject/update",
    SUBJECT_DELETE                                  = "/subject/delete",

    SUBJECT_CATEGORY_LIST                           = "/subject/category/list",
    SUBJECT_CATEGORY_DETAIL                         = "/subject/category/detail",
    SUBJECT_CATEGORY_SAVE                           = "/subject/category/save",
    SUBJECT_CATEGORY_UPDATE                         = "/subject/category/update",
    SUBJECT_CATEGORY_DELETE                         = "/subject/category/delete",
    SUBJECT_CATEGORY_TAGSLIST                       = "/subject/category/tags_list",

    SUBJECT_DIFFCULTY_LIST                          = "/subject/difficulty/list",
    SUBJECT_DIFFCULTY_DETAIL                        = "/subject/difficulty/detail",
    SUBJECT_DIFFCULTY_SAVE                          = "/subject/difficulty/save",
    SUBJECT_DIFFCULTY_UPDATE                        = "/subject/difficulty/update",
    SUBJECT_DIFFCULTY_DELETE                        = "/subject/difficulty/delete",

    SUBJECT_TAGS_LIST                               = "/subject/tags/list",
    SUBJECT_TAGS_DETAIL                             = "/subject/tags/detail",
    SUBJECT_TAGS_SAVE                               = "/subject/tags/save",
    SUBJECT_TAGS_UPDATE                             = "/subject/tags/update",
    SUBJECT_TAGS_DELETE                             = "/subject/tags/delete",

    TAGS_LIST                                       = "/tags/list",
    TAGS_ADD                                        = "/tags/add",
    TAGS_UPDATE                                     = "/tags/update",
    TAGS_DELETE                                     = "/tags/delete",

    TAGS_CATEGORY_DELETE                            = "/tags/category/delete",
    TAGS_CATEGORY_UPDATE                            = "/tags/category/update",
    TAGS_CATEGORY_LIST                              = "/tags/category/list",
    TAGS_CATEGORY_ADD                               = "/tags/category/add",

    //课程问题
    TAGS_PROBLEM_DELETE                              = "/tags/problem/delete",
    TAGS_PROBLEM_UPDATE                              = "/tags/problem/update",
    TAGS_PROBLEM_ADD                                 = "/tags/problem/add",
    TAGS_PROBLEM_LIST                                = "/tags/problem/list",



    TAGS_LEVEL_DELETE                               = "/tags/level/delete",
    TAGS_LEVEL_UPDATE                               = "/tags/level/update",
    TAGS_LEVEL_ADD                                  = "/tags/level/add",
    TAGS_LEVEL_LIST                                 = "/tags/level/list",


    ADS_LIST                                        = "/ads/list",
    ADS_DETAIL                                      = "/ads/detail",
    ADS_UPDATE                                      = "/ads/update",
    ADS_SAVE                                        = "/ads/save",
    ADS_DELETE                                      = "/ads/delete",

    FAQ_LIST                                        = "/faq/list",
    FAQ_DETAIL                                      = "/faq/detail",
    FAQ_ADD                                         = "/faq/add",
    FAQ_UPDATE                                      = "/faq/update",
    FAQ_DELETE                                      = "/faq/delete",

    FEEDBACK_LIST                                   = "/feedback/list",
    FEEDBACK_DETAIL                                 = "/feedback/detail",
    FEEDBACK_DELETE                                 = "/feedback/delete",

    SOLUTION_LIST                                   = "/solution/list",
    SOLUTION_ADD                                    = "/solution/add",
    SOLUTION_UPDATE                                 = "/solution/update",
    SOLUTION_DETAIL                                 = "/solution/detail",
    SOLUTION_DELETE                                 = "/solution/delete",


    //获取可播放链接
    VOD_PLAYABLE                                    = "/vod/playable",


    
    VOD_SIG                                         = "/vod/sig",
    OSS_STS                                         = "/oss/sts",
    STS                                             = "/oss/sts",

    SCHEDULE_INSERT                                 = "/schedule/course/insertCourse",
    SCHEDULE_COURES_SCHEDULE_TYPE                   = "/schedule/course/schedule_type",
    SCHEDULE_COURES_AI_COURSE                       = "/schedule/course/ai_course",
    
    NOTIFICATION_DELETE                             = "/notification/delete",
    NOTIFICATION_DETAIL                             = "/notification/detail",
    NOTIFICATION_LIST                               = "/notification/list",
    NOTIFICATION_UPDATE                             = "/notification/update",
    NOTIFICATION_ADD                                = "/notification/add",
    NOTIFICATION_CLIENT                             = "/notification/client",

    // ai接口
    AI_EVALUATION_LIST                              = "/ai/evaluation/list",
    AI_WRONG_LIST                                   = "/ai/wrong/list",
    AI_COURSE_INIT                                  = "/ai/course/init",
    AI_LIST                                         = "/ai/list",
    AI_AI_LIST                                      = "/ai/ai_list",
    AI_COURSE_TIPS                                  = "/ai/course/tips",//获取题目提示
    AI_WRONG_ADD                                    = "/ai/wrong/add",
    AI_EVALUATION_DETAIL                            = "/ai/evaluation/detail",
    AI_DETAIL                                       = "/ai/detail",
    AI_COURSE_SKIP                                  = "/ai/course/skip",
    AI_COURSE_START                                 = "/ai/course/start",
    AI_COURSE_MARK                                  = "/ai/course/mark",
    AI_COURSE_WRONG_ITEMS                           = "/ai/course/wrong_items",
    AI_COURSE_CONTINUE                              = "/ai/course/continue",
    AI_COURSE_FINISH                                = "/ai/course/finish",
    AI_EVALUATION_UPDATE                            = "/ai/evaluation/update",
    AI_UPDATE                                       = "/ai/update",
    AI_WRONG_UPDATE                                 = "/ai/wrong/update",
    AI_EVALUATION_DELETE                            = "/ai/evaluation/delete",
    AI_WRONG_DELETE                                 = "/ai/wrong/delete",
    AI_COURSE_SUBJECT_CHOICES                       = "/ai/course/subject/choices",
    AI_ADD                                          = "/ai/add",
    AI_EVALUATION_ADD                               = "/ai/evaluation/add",
    AI_WRONG_DETAIL                                 = "/ai/wrong/detail",
    AI_DELETE                                       = "/ai/delete",
    AI_SEGMENT_LIST                                 = "/ai/segment/list",
    AI_SEGMENT_ADD                                  = "/ai/segment/add",
    AI_CHOICE_UPDATE                                = "/ai/choice/update",
    AI_CHOICE_DELETE                                = "/ai/choice/delete",
    AI_CHOICE_ADD                                   = "/ai/choice/add",
    AI_SEGMENT_UPDATE                               = "/ai/segment/update",
    AI_SEGMENT_DELETE                               = "/ai/segment/delete",
    AI_OPTION_UPDATE                                = "/ai/option/update",
    AI_CHOICE_LIST                                  = "/ai/choice/list",
    AI_OPTION_DELETE                                = "/ai/option/delete",
    AI_OPTION_ADD                                   = "/ai/option/add",
    AI_CHOICE_DETAIL                                = "/ai/choice/detail",

    // 练习题
    SUBJECT_COMMENT_LIST                            = "/subject/comment/list",
    SUBJECT_COMMENT_UPDATE                          = "/subject/comment/update",
    SUBJECT_COMMENT_DETAIL                          = "/subject/comment/detail",
    SUBJECT_COMMENT_DELETE                          = "/subject/comment/delete",

    //文章
    ARTICLE_LIST                                    = "/article/list",
    ARTICLE_CATEGORIES_LIST                         = "/article/categories/list",
    ARTICLE_UPDATE                                  = "/article/update",
    ARTICLE_CATEGORIES_UPDATE                       = "/article/categories/update",
    ARTICLE_CATEGORIES_DELETE                       = "/article/categories/delete",
    ARTICLE_CATEGORIES_ADD                          = "/article/categories/add",
    ARTICLE_CATEGORIES_DETAIL                       = "/article/categories/detail",
    ARTICLE_DELETE                                  = "/article/delete",
    ARTICLE_ADD                                     = "/article/add",
    ARTICLE_DETAIL                                  = "/article/detail",

    //平台协议
    SYSTEM_AGR_LIST                                 = "/system/agr/list",
    SYSTEM_AGR_ADD                                  = "/system/agr/add",
    SYSTEM_AGR_DETAIL                               = "/system/agr/detail",
    SYSTEM_DETAIL                                   = "/system/detail",
    SYSTEM_UPDATE                                   = "/system/update",

    //获取系统操作日志
    SYSTEM_OPER_LIST                                = "/system/oper/list",
    SYSTEM_OPER_RESTORE                             = "/system/oper/restore",
    SYSTEM_OPER_ROLLBACK                            = "/system/oper/rollback",

    SYSTEM_DELETE                                   = "/system/delete",

    //活动接口
    ACTIVITY_LIST                                   = "/activity/list",
    ACTIVITY_DELETE                                 = "/activity/delete",
    ACTIVITY_DETAIL                                 = "/activity/detail",
    ACTIVITY_SAVE                                   = "/activity/save",
    
    // 创建腾讯直播房间
    LIVE_SIG                                        = "/live/sig",

    //获取用户今日任务
    USER_TASK                                       = "/user/task",

    AUDIO_LIST                                      = "/audio/list",
    AUDIO_DETAIL                                    = "/audio/detail",
    AUDIO_UPDATE                                    = "/audio/update",
    AUDIO_DELETE                                    = "/audio/delete",
    AUDIO_ADD                                       = "/audio/add",

    VIDEO_LIST                                      = "/video/list",
    VIDEO_DELETE                                    = "/video/delete",
    VIDEO_UPDATE                                    = "/video/update",
    VIDEO_CREATE                                    = "/video/create",
    VIDEO_DETAIL                                    = "/video/detail",

    COURSE_HAS                                      = "/course/has",

    TICKET                                          = "/ticket",

    PUB_METRONOME                                   = "/pub/metronome",

    //整体
    BASIS_SCHEDULE                                  = "/basis/schedule",
    BASIS_USER                                      = "/basis/user",
    BASIS_COURSE                                    = "/basis/course",
    BASIS_HUIKE                                     = "/basis/huike",
    BASIS_BASIS                                     = "/basis/basis",

    //AI音频
    AI_AUDIO_ADD                                    = "/ai/audio/add",
    AI_AUDIO_DELETE                                 = "/ai/audio/delete",
    AI_AUDIO_UPDATE                                 = "/ai/audio/update"
}

export default API;