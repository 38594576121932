import Model from "./model";

export default class OrganizationRating extends Model {
    public org_id?: number;
    public score?: number;
    public score_reason?: string;
    public score_time?: string;
    public size?: string;
    public location?: string;
    public student_quality?: string;
}