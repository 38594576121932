import Page from "../components/Page";
import { Set } from "immutable";

class RouterConfig {
  public page?: Page;
  public name?: string;
  public mustLogin: boolean = false;
  // 如果不异步加载，需要设置指定的组件
  public component?: any;
  public componentPath?: string;
  public parentConfig?: RouterConfig;
  // 是否异步加载
  public async: boolean = false;
  public exact: boolean = true;
  public children: Set<RouterConfig> = Set();
  public hasChildren: boolean = false;

  constructor(
    page?: Page,
    name?: string,
    mustLogin?: boolean,
    async?: boolean,
    hasChildren?: boolean,
    component?: any,
    componentPath?: string
  ) {
    this.page = page;
    this.name = name;
    if (mustLogin) {
      this.mustLogin = mustLogin;
    }
    if (async) {
      this.async = async;
    } else {
      if (!component) {
        throw new Error("非异步加载的组件，必须指定相应组件");
      } else {
        this.component = component;
      }
    }
    if (hasChildren) {
      this.hasChildren = hasChildren;
    }

    this.componentPath = componentPath;
  }
}

export default RouterConfig;
