import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getTicket(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TICKET, { params: params });
    yield put({
      type: Actions.TICKET.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TICKET.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTicket() {
  while (true) {
    const action = yield take(Actions.TICKET.GET);
    yield fork(getTicket, action.params);
  }
}


