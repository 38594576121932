import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* postScheduleInsert(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_INSERT, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_INSERT.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_INSERT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleInsert() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_INSERT.POST);
    yield fork(postScheduleInsert, action.params);
  }
}

//列表
export function* getScheduleType(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SCHEDULE_COURES_SCHEDULE_TYPE, {
      params: params
    });
    yield put({
      type: Actions.SCHEDULE_COURES_SCHEDULE_TYPE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_COURES_SCHEDULE_TYPE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchScheduleType() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_COURES_SCHEDULE_TYPE.GET);
    yield fork(getScheduleType, action.params);
  }
}

//列表
export function* getAiCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SCHEDULE_COURES_AI_COURSE, {
      params: params
    });
    yield put({
      type: Actions.SCHEDULE_COURES_AI_COURSE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_COURES_AI_COURSE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiCourse() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_COURES_AI_COURSE.GET);
    yield fork(getAiCourse, action.params);
  }
}