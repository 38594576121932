import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getSubjectDiffcultyList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_DIFFCULTY_LIST, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectDiffcultyList() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DIFFCULTY_LIST.GET);
    yield fork(getSubjectDiffcultyList, action.params);
  }
}

export function* getSubjectDiffcultyDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_DIFFCULTY_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectDiffcultyDetail() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DIFFCULTY_DETAIL.GET);
    yield fork(getSubjectDiffcultyDetail, action.params);
  }
}

export function* postSubjectDiffcultyUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_DIFFCULTY_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectDiffcultyUpdate() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DIFFCULTY_UPDATE.POST);
    yield fork(postSubjectDiffcultyUpdate, action.params);
  }
}

export function* postSubjectDiffcultySave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_DIFFCULTY_SAVE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_SAVE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_SAVE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectDiffcultySave() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DIFFCULTY_SAVE.POST);
    yield fork(postSubjectDiffcultySave, action.params);
  }
}

export function* postSubjectDiffcultyDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_DIFFCULTY_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DIFFCULTY_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectDiffcultyDelete() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DIFFCULTY_DELETE.POST);
    yield fork(postSubjectDiffcultyDelete, action.params);
  }
}
