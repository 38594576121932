import { all } from "redux-saga/effects";

import { 
  watchServerTime, 
  watchGetAreaData,
  watchPubMetronome,
} from "./pub";
import { watchDoLogin } from "./login";
import { watchReloadCourses } from "./local";
import { watchOssSts } from "./oss";
import {
  watchUserList,
  watchUserDetail,
  watchCourses,
  watchAddSchedule,
  watchUpdateSchedule,
  watchAdjustCourse,
  watchAbsentList,
  watchManualFinishCourse,
  watchScheduleList,
  watchPostUpdateUser,
  watchUserGenStudent,
  watchUserGenTeacher,
  watchUserDelete,
  watchUserCreate,
  watchUserMainTeacherBatchBind,
  watchUserHomeworkList,
  watchUserHomeworkSteps,
  watchUserHomeworkSubtects,
  watchHomeWorkReViewDelete,
  watchHomeWorkReViewSubmit,
  watchHomeWorkReViewList,
  watchScheduleDelete,
  watchScheduleSelect,
  watchUserTask,
  watchComponentList,
  watchUserComponent,
  watchUserUpdateUserComponent,
  watchUserScheduleList,
  watchuserHomeworkAnswer,
} from "./user";
import {
  watchAdminList,
  watchAdminAdd,
  watchAdminUpdate,
  watchAdminDelete
} from "./admin";
import { watchAdminLogs, watchLogList, watchLogDelete } from "./log";
import {
  watchpostOrgCreate,
  watchOrgList,
  watchOrgUpdate,
  watchOrgDelete,
  watchOrgDetail,
  watchOrgSettingDetail,
  watchOrgSettingUpdate,
} from "./organization";
import { watchVodPlayable, watchVodSig } from "./vod";
import {
  watchGuideList,
  watchGuideAdd,
  watchGuideUpdate,
  watchGuideCategoryList,
  watchGuideCategoryAdd,
  watchGuideCategoryUpdate,
  watchGuideCategoryDelete
} from "./guide";
import {
  watchMelodyList,
  watchMelodyAdd,
  watchMelodyUpdate,
  watchMelodyDelete,
  watchMelodyDetail,
  watchMelodyGuideDetail,
  watchGuideDetail,
  watchMelodyGuideDelete,
  watchGuideDelete,
  watchMelodyGuideAdd,
  watchMelodyImageDelete,
  watchMelodyImageUpdate,
  watchMelodyImageAdd,
  watchMelodyGuideUpdate,
} from "./melody";
import {
  watchTextBookList,
  watchTextBookDelete,
  watchTextBookUpdate,
  watchTextBookAdd,
  watchTextBookChapterDelete,
  watchTextBookChapterUpdate,
  watchTextBookChapterAdd,
  watchTextBookChapterList,
  watchTextBookLessonDelete,
  watchTextBookLessonList,
  watchTextBookLessonAdd,
  watchTextBookLessonUpdate
} from "./textbook";
import {
  watchFaqList,
  watchFaqAdd,
  watchFaqUpdate,
  watchFaqDelete,
  watchFaqDetail
} from "./faq";
import {
  watchFeedBackList,
  watchFeedBackDetail,
  watchFeedBackDelete
} from "./feedback";
import {
  watchSolutionList,
  watchSolutionDetail,
  watchSolutionAdd,
  watchSolutionDelete,
  watchSolutionUpdate
} from "./solution";
import {
  watchSubjectAdd,
  watchSubjectUpdate,
  watchSubjectDetail,
  watchSubjectList,
  watchSubjectDelete
} from "./subject";
import {
  watchSubjectDiffcultyList,
  watchSubjectDiffcultySave,
  watchSubjectDiffcultyUpdate,
  watchSubjectDiffcultyDetail,
  watchSubjectDiffcultyDelete
} from "./difficulty";
import {
  watchSubjectCategoryList,
  watchSubjectCategorySave,
  watchSubjectCategoryDetail,
  watchSubjectCategoryUpdate,
  watchSubjectCategoryDelete,
  watchSubjectCategoryTagsList
} from "./category";
import {
  watchSubjectTagList,
  watchSubjectTagDetail,
  watchSubjectTagSave,
  watchSubjectTagUpdate,
  watchSubjectTagDelete,
  watchTagsCategoryDelete,
  watchTagsCategoryUpdate,
  watchTagsCategoryAdd,
  watchTagsCategoryList,
  watchTagsLevelList,
  watchTagsLevelAdd,
  watchTagsLevelDelete,
  watchTagsLevelUpdate,
  watchTagsProblemDelete,
  watchTagsProblemUpdate,
  watchTagsProblemAdd,
  watchTagsProblemList,
  watchTagList,
  watchTagUpdate,
  watchTagDelete,
  watchTagAdd,
} from "./tag";
import {
  watchAdsList,
  watchAdsDetail,
  watchAdsAdd,
  watchAdsDelete,
  watchAdsUpdate
} from "./ads";
import { watchtestList } from "./sts";


import { watchScheduleInsert, watchAiCourse } from "./schedule";

import {
  watchBasisBasis,
  watchBasisCourse,
  watchBasisHuike,
  watchBasisUser,
  watchBasisSchedule,
} from "./dashboard";

import { 
  watchAudioList,
  watchAudioDetail, 
  watchAudioUpdate,
  watchAudioDelete,
  watchAudioAdd
} from './audio';

import {
  watchNotificationList,
  watchNotificationDelete,
  watchNotificationDetail,
  watchNotificationAdd,
  watchNotificationUpdate,
  watchNotificationClient
} from "./notification";

// ai
import {
  watchAiEvaluationList, //ai课点评视频列表
  watchAiWrongList, //ai课错误列表
  watchAiCourseInit,
  watchAiList,
  watchAiCourseTips,
  watchAiWrongAdd,
  watchAiEvaluationDetail,
  watchAiDetail,
  watchAiCourseSkip,
  watchAiCourseStart,
  watchAiCourseMark,
  watchAiCourseWrongItems,
  watchAiCourseContinue,
  watchAiCourseFinish,
  watchAiEvaluationUpdate,
  watchAiUpate,
  watchAiWrongUpate,
  watchAiEvaluationDelete,
  watchAiWrongDelete,
  watchAiCourseSubjectChoices,
  watchAiAdd,
  watchAiEvaluationAdd,
  watchAiWrongDetail,
  watchAiDelete,
  watchAiSegmentList,
  watchAiSegmentAdd,
  watchAiChoiceUpdate,
  watchAiChoiceDelete,
  watchAiChoiceAdd,
  watchAiSegmentUpdate,
  watchAiSegmentDelete,
  watchAiOptionUpdate,
  watchAiChoiceList,
  watchAiOptionDelete,
  watchAiOptionAdd,
  watchAiChoiceDetail,
  watchAiAiList
} from "./aiclass";

import { watchScheduleType } from "./schedule";

import { watchCourseHas } from './course';

import {  
  watchActivityList,
  watchActivityDetail,
  watchActivityDelete,
  watchActivitySave,
  
}  from './activity';

import { watchTicket } from './ticket';

// 文章
import {
  watchAticleList,
  watchAticleDetail,
  watchAticleCategoriesList,
  watchArticleUpdate,
  watchArticleCategoriesUpdate,
  watchArticleCategoriesDelete,
  watchArticleCategoriesAdd,
  watchArticleCategoriesDetail,
  watchArticleDelete,
  watchArticleAdd,
} from './article';


import { watchLiveSig } from './live';

import { 
  watchVideoList,
  watchVideoDetail,
  watchVideoUpdate,
  watchVideoDelete,
  watchVideocCreate,
} from './videos';

import {
  watchAiAudioAdd,
  watchAiAudioDelete,
  watchAiAudioUpdate
} from './aiaudio';

import {
  watchSystemAgrList,
  watchSystemGarAdd,
  watchSystemAgrDetail,
  watchSystemGarUpdate,
  watchSystemDetail,
  watchSystemOperList,
  watchSystemDelete,
  watchSystemOperRestore,
  watchSystemOperRollback,
} from './system';


export default function* rootSaga() {
  yield all([
    watchServerTime(),
    watchGetAreaData(),
    watchDoLogin(),

    watchUserList(),
    watchUserDetail(),
    watchUserDelete(),
    watchUserCreate(),
    watchPostUpdateUser(),
    watchUserGenStudent(),
    watchUserGenTeacher(),
    watchUserMainTeacherBatchBind(),
    watchUserHomeworkList(),
    watchUserHomeworkSteps(),
    watchUserHomeworkSubtects(),
    watchUserTask(),
    watchComponentList(),
    watchUserComponent(),
    watchUserUpdateUserComponent(),
    watchUserScheduleList(),
    watchuserHomeworkAnswer(),


    watchHomeWorkReViewDelete(),
    watchHomeWorkReViewSubmit(),
    watchHomeWorkReViewList(),

    watchCourses(),
    watchAddSchedule(),
    watchUpdateSchedule(),
    watchScheduleList(),
    watchScheduleDelete(),
    watchScheduleSelect(),

    watchAdjustCourse(),

    watchReloadCourses(),
    watchAbsentList(),
    watchManualFinishCourse(),

    watchAdminList(),
    watchAdminAdd(),
    watchAdminUpdate(),
    watchAdminDelete(),
    watchAdminLogs(),

    watchLogList(),
    watchLogDelete(),

    watchOrgList(),
    watchOrgDetail(),
    watchpostOrgCreate(),
    watchOrgUpdate(),
    watchOrgDelete(),
    watchOrgSettingDetail(),
    watchOrgSettingUpdate(),

    watchGuideList(),
    watchGuideAdd(),
    watchGuideUpdate(),

    watchGuideCategoryList(),
    watchGuideCategoryAdd(),
    watchGuideCategoryUpdate(),
    watchGuideCategoryDelete(),

    watchMelodyList(),
    watchMelodyAdd(),
    watchMelodyUpdate(),
    watchMelodyDelete(),
    watchMelodyDetail(),
    watchMelodyGuideDetail(),
    watchMelodyGuideDelete(),
    watchGuideDetail(),
    watchGuideDelete(),
    watchMelodyGuideAdd(),
    watchMelodyImageDelete(),
    watchMelodyImageUpdate(),
    watchMelodyImageAdd(),
    watchMelodyGuideUpdate(),

    watchLiveSig(),

    watchTextBookList(),
    watchTextBookAdd(),
    watchTextBookUpdate(),
    watchTextBookDelete(),

    watchTextBookChapterList(),
    watchTextBookChapterAdd(),
    watchTextBookChapterUpdate(),
    watchTextBookChapterDelete(),

    watchTextBookLessonList(),
    watchTextBookLessonAdd(),
    watchTextBookLessonUpdate(),
    watchTextBookLessonDelete(),

    watchSubjectList(),
    watchSubjectDetail(),
    watchSubjectAdd(),
    watchSubjectUpdate(),
    watchSubjectDelete(),

    watchTicket(),

    watchSubjectDiffcultyList(),
    watchSubjectDiffcultyDetail(),
    watchSubjectDiffcultySave(),
    watchSubjectDiffcultyUpdate(),
    watchSubjectDiffcultyDelete(),

    watchSubjectCategoryList(),
    watchSubjectCategorySave(),
    watchSubjectCategoryDetail(),
    watchSubjectCategoryUpdate(),
    watchSubjectCategoryDelete(),
    watchSubjectCategoryTagsList(),

    watchSubjectTagList(),
    watchSubjectTagDetail(),
    watchSubjectTagSave(),
    watchSubjectTagUpdate(),
    watchSubjectTagDelete(),
    watchTagsLevelList(),
    watchTagsLevelAdd(),
    watchTagsLevelDelete(),
    watchTagsLevelUpdate(),

    watchTagsProblemDelete(),
    watchTagsProblemUpdate(),
    watchTagsProblemAdd(),


    watchTagsCategoryAdd(),
    watchTagsCategoryList(),
    watchTagsCategoryDelete(),
    watchTagsCategoryUpdate(),
    watchTagsProblemList(),
    watchTagList(),
    watchTagUpdate(),
    watchTagDelete(),
    watchTagAdd(),


    watchAdsList(),
    watchAdsDetail(),
    watchAdsAdd(),
    watchAdsUpdate(),
    watchAdsDelete(),

    watchFaqList(),
    watchFaqDetail(),
    watchFaqAdd(),
    watchFaqUpdate(),
    watchFaqDelete(),

    watchFeedBackList(),
    watchFeedBackDetail(),
    watchFeedBackDelete(),

    watchSolutionList(),
    watchSolutionDetail(),
    watchSolutionAdd(),
    watchSolutionDelete(),
    watchSolutionUpdate(),

    watchVodPlayable(),
    watchVodSig(),

    watchOssSts(),

    watchScheduleInsert(),

    watchtestList(),

    watchAudioList(),
    watchAudioDetail(),
    watchAudioUpdate(),
    watchAudioDelete(),
    watchAudioAdd(),

    watchNotificationList(),
    watchNotificationDelete(),
    watchNotificationDetail(),
    watchNotificationAdd(),
    watchNotificationUpdate(),
    watchNotificationClient(),

    watchAiEvaluationList(),
    watchAiWrongList(),
    watchAiCourseInit(),
    watchAiList(),
    watchAiCourseTips(),
    watchAiWrongAdd(),
    watchAiEvaluationDetail(),
    watchAiDetail(),
    watchAiCourseSkip(),
    watchAiCourseStart(),
    watchAiCourseMark(),
    watchAiCourseWrongItems(),
    watchAiCourseContinue(),
    watchAiCourseFinish(),
    watchAiEvaluationUpdate(),
    watchAiUpate(),
    watchAiWrongUpate(),
    watchAiEvaluationDelete(),
    watchAiWrongDelete(),
    watchAiCourseSubjectChoices(),
    watchAiAdd(),
    watchAiEvaluationAdd(),
    watchAiWrongDetail(),
    watchAiDelete(),
    watchAiSegmentList(),
    watchAiSegmentAdd(),
    watchAiChoiceUpdate(),
    watchAiChoiceDelete(),
    watchAiChoiceAdd(),
    watchAiSegmentUpdate(),
    watchAiSegmentDelete(),
    watchAiOptionUpdate(),
    watchAiChoiceList(),
    watchAiOptionDelete(),
    watchAiOptionAdd(),
    watchAiChoiceDetail(),

    watchScheduleType(),
    watchAiCourse(),

    // 文章
    watchAticleList(),
    watchAticleDetail(),
    watchAticleCategoriesList(),
    watchArticleUpdate(),
    watchArticleCategoriesUpdate(),
    watchArticleCategoriesDelete(),
    watchArticleCategoriesAdd(),
    watchArticleCategoriesDetail(),
    watchArticleDelete(),
    watchArticleAdd(),

    watchActivityList(),
    watchActivityDetail(),
    watchActivityDelete(),
    watchActivitySave(),


    watchSystemAgrList(),
    watchSystemGarAdd(),
    watchSystemAgrDetail(),
    watchSystemGarUpdate(),
    watchSystemDetail(),
    watchSystemOperList(),
    watchSystemDelete(),
    watchSystemOperRestore(),
    watchSystemOperRollback(),

    watchCourseHas(),

    watchPubMetronome(),

    watchVideoList(),
    watchVideoDetail(),
    watchVideoUpdate(),
    watchVideoDelete(),
    watchVideocCreate(),

    watchBasisSchedule(),
    watchBasisBasis(),
    watchBasisHuike(),
    watchBasisCourse(),
    watchBasisUser(),

    watchAiAiList(),

    watchAiAudioAdd(),
    watchAiAudioDelete(),
    watchAiAudioUpdate()
  ]);
}
