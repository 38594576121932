import * as React from "react";
import { bindActionCreators } from "redux";
import { Card, Form, Input, Row, Checkbox, Button, notification } from "antd";

/* third party */

/* scripts */
import { withRouter, connect, replace } from "core";
import { IFormProps } from "core/interfaces/icommon_props";

import View from "core/components/View";
import Content from "core/components/Content";
import Env from "core/env";
import Actions from "core/net/actions";
import Global from "core/env/global";
import { Pages } from "core/enums/pages";
import * as CryptoJS from "crypto-js";
import { JSEncrypt } from "jsencrypt";
import NetWork from "core/net";
import Admin from "models/admin";
import { plainToClass } from "class-transformer";
import AppGlobal from "app_global";

interface ILoginProps extends IFormProps {
  loginResult: any;
  doLogin: (p) => {};
  replace: any;
}

function mapStateToProps(state: any) {
  return {
    loginResult: state.bm[Actions.LOGIN.RAW]
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      doLogin: p => {
        return { type: Actions.LOGIN.POST, params: p };
      },
      replace
    },
    dispatch
  );
}

@withRouter()
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Login extends View<ILoginProps, {}> {
  public state: { [key: string]: any } = {
    loginResult: null,
    password: null,
    checked: false,
    tel: null
  };

  private isProcessing: boolean = false;

  constructor(props: ILoginProps) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.isProcessing) return;
        this.isProcessing = true;

        let password = values["pw"];
        password = CryptoJS.MD5(password).toString();
        password = CryptoJS.MD5(password).toString();
        password = CryptoJS.MD5(password).toString();
        password = CryptoJS.MD5(password).toString();

        const rsaEncrypt = new JSEncrypt();
        rsaEncrypt.setPublicKey(Global.PUB_RSA);
        password = rsaEncrypt.encrypt(password);
        this.setState(
          {
            password: password,
            tel: values["tel"]
          },
          () => {
            this.props.doLogin({
              tel: values["tel"],
              pw: password
            });
          }
        );
      } else {
        this.isProcessing = false;
        notification.error({
          message: "登录失败",
          description: "请正确填写管理员登录信息"
        });
      }
    });
  }

  public static getDerivedStateFromProps(props, state) {
    if (props.loginResult && props.loginResult.__processed === false) {
      return {
        loginResult: props.loginResult
      };
    }
    return null;
  }

  componentDidMount() {
    const tel = localStorage.getItem("tel");
    const isPassword = localStorage.getItem("isPassword");
    const password = localStorage.getItem("password");
    if (isPassword === "true") {
      this.setState(
        {
          checked: true,
          tel: tel,
          password: password
        },
        () => {
          const config = {
            auto: true,
            tel: tel,
            pw: password
          };
          this.props.form.setFieldsValue(config);
          this.props.doLogin({
            tel: tel,
            pw: password
          });
        }
      );
    }
  }

  componentDidUpdate() {
    if (this.props.loginResult && !this.props.loginResult.__processed) {
      this.isProcessing = false;
      this.props.loginResult.__processed = true;
      if (this.state.loginResult.data.err_code === undefined) {
        const admin: Admin = plainToClass(Admin, this.state.loginResult.data);
        console.log(this.state.loginResult);
        AppGlobal.adminData = admin;
        console.log(AppGlobal.adminData);
        localStorage.setItem("isLogin", "true");
        localStorage.setItem(
          "isPassword",
          this.state.checked ? "true" : "false"
        );
        localStorage.setItem("token", this.state.loginResult.data.token);
        if (this.state.checked) {
          console.log("--------");
          localStorage.setItem("password", this.state.password);
          localStorage.setItem("tel", this.state.tel);
        } else {
          localStorage.removeItem("tel");
          localStorage.removeItem("password");
        }

        NetWork.refreshConfig();
        const dashboardPath = Global.routers.getPageFullPath(Pages.DASHBOARD);
        if (dashboardPath) {
          this.props.replace(dashboardPath.path);
        }
      } else {
        notification.destroy();
        notification.error({
          message: "登录失败",
          description: this.state.loginResult.data.err_msg
        });
      }
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content
        style={{
          background: `url(${require("../../assets/bag.png")}) no-repeat center center / 100% 100%`,
          display: "flex",
          justifyContent: "top",
          alignItems: "center",
          position: "relative",
          flexDirection: "column"
        }}
      >
        <Card
          style={{
            borderRadius: 10,
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            height: "auto",
            width: 400,
            margin: "0 auto",
            marginTop: 144
          }}
        >
          <div
            style={{
              background: `url(${require("../../assets/icon.png")}) no-repeat center center / 100% 100%`,
              width: 156,
              height: 48,
              margin: "0 auto",
              marginBottom: 40,
              marginTop: 20
            }}
          ></div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label="">
              {getFieldDecorator("tel", {
                validateTrigger: ["onBlur"],
                rules: [
                  {
                    required: true,
                    pattern: /^1(([3-9][0-9])\d{8}$)/,
                    message: "管理员手机号码不正确"
                  }
                ]
              })(<Input size="large" placeholder="账号" />)}
            </Form.Item>
            <Form.Item
              label=""
              style={{
                marginBottom: 10
              }}
            >
              {getFieldDecorator("pw", {
                validateTrigger: ["onBlur"],
                rules: [
                  {
                    required: true,
                    min: 6,
                    message: "管理员密码不正确"
                  }
                ]
              })(<Input size="large" placeholder="密码" type="password" />)}
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: 34
              }}
            >
              <Row>
                {getFieldDecorator("auto", {
                  valuePropName: "checked"
                })(
                  <Checkbox
                    onChange={e => {
                      console.log(e.target.checked);
                      this.setState({
                        checked: e.target.checked
                      });
                    }}
                  >
                    记住密码
                  </Checkbox>
                )}
              </Row>
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: 0
              }}
            >
              <Row type="flex" justify="center">
                <Button
                  type="primary"
                  size="large"
                  block={true}
                  htmlType="submit"
                  loading={this.state.isLogin}
                  style={{ color: "black" }}
                >
                  登录
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
        <div
          style={{
            paddingTop: 24,
            color: "rgba(0,0,0,0.35)"
          }}
        >
          v{Env.VERSION}({Env.BUILD})
        </div>
      </Content>
    );
  }
}

export default Form.create()(Login);
