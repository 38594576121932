import { withRouter as withRouterComponent } from "react-router-dom";
import { connect as connectComponent } from "react-redux";
import {
  push as pushPage,
  replace as replacePage,
  go as goBy,
  goBack as goBackward
} from "connected-react-router";

/**
 * This is a decorator factory of the `withRouter`
 * it's only use the `withRouter` decoratively
 * so if you wanna use the `withRouter` when export a class, then import it directly then use.
 */
export const withRouter = () => {
  return (target: any) => withRouterComponent(target) as any;
};

/**
 * This is a decorator factory of the `connect`
 * it's only use the `connect` decoratively
 * so if you wanna use the `connect` when export a class, then import it directly then use.
 */
export const connect = (mapStateToProps: any, actions: any) => {
  return (target: any) =>
    connectComponent(mapStateToProps, actions)(target) as any;
};

export function push(
  key: string,
  params?: {
    [s: string]: any;
  }
) {
  const ps: string[] = [];
  if (params) {
    for (const k in params) {
      if (params.hasOwnProperty(k)) {
        ps.push(k + "=" + params[k]);
      }
    }
  }
  return pushPage(`${key}` + (ps.length ? `?${ps.join("&")}` : ""));
}

export function replace(
  key: string,
  params?: {
    [s: string]: any;
  }
) {
  const ps: string[] = [];
  if (params) {
    for (const k in params) {
      if (params.hasOwnProperty(k)) {
        ps.push(k + "=" + params[k]);
      }
    }
  }
  return replacePage(`${key}` + (ps.length ? `?${ps.join("&")}` : ""));
}

export function go(step: number) {
  return goBy(step);
}

export function goBack() {
  return goBackward();
}
