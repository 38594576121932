import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

//列表
export function* getSolutionList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SOLUTION_LIST, {
      params: params
    });
    yield put({
      type: Actions.SOLUTION_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SOLUTION_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSolutionList() {
  while (true) {
    const action = yield take(Actions.SOLUTION_LIST.GET);
    yield fork(getSolutionList, action.params);
  }
}

//详情
export function* getSolutionDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SOLUTION_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.SOLUTION_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SOLUTION_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSolutionDetail() {
  while (true) {
    const action = yield take(Actions.SOLUTION_DETAIL.GET);
    yield fork(getSolutionDetail, action.params);
  }
}

//添加
export function* postSolutionAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SOLUTION_ADD, { ...params });
    yield put({
      type: Actions.SOLUTION_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SOLUTION_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSolutionAdd() {
  while (true) {
    const action = yield take(Actions.SOLUTION_ADD.POST);
    yield fork(postSolutionAdd, action.params);
  }
}

//修改
export function* postSolutionUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SOLUTION_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.SOLUTION_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SOLUTION_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSolutionUpdate() {
  while (true) {
    const action = yield take(Actions.SOLUTION_UPDATE.POST);
    yield fork(postSolutionUpdate, action.params);
  }
}

//删除
export function* postSolutionDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SOLUTION_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SOLUTION_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SOLUTION_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSolutionDelete() {
  while (true) {
    const action = yield take(Actions.SOLUTION_DELETE.POST);
    yield fork(postSolutionDelete, action.params);
  }
}
