import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getGuideList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.GUIDE_LIST, {
      params: params
    });
    yield put({
      type: Actions.GUIDE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchGuideList() {
  while (true) {
    const action = yield take(Actions.GUIDE_LIST.GET);
    yield fork(getGuideList, action.params);
  }
}

export function* postGuideAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.GUIDE_ADD, { ...params });
    yield put({
      type: Actions.GUIDE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGuideAdd() {
  while (true) {
    const action = yield take(Actions.GUIDE_ADD.POST);
    yield fork(postGuideAdd, action.params);
  }
}

export function* postGuideUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.GUIDE_UPDATE, { ...params });
    yield put({
      type: Actions.GUIDE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGuideUpdate() {
  while (true) {
    const action = yield take(Actions.GUIDE_UPDATE.POST);
    yield fork(postGuideUpdate, action.params);
  }
}

export function* getGuideCategoryList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.GUIDE_CATEGORY_LIST, {
      params: params
    });
    yield put({
      type: Actions.GUIDE_CATEGORY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_CATEGORY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchGuideCategoryList() {
  while (true) {
    const action = yield take(Actions.GUIDE_CATEGORY_LIST.GET);
    yield fork(getGuideCategoryList, action.params);
  }
}

export function* postGuideCategoryAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.GUIDE_CATEGORY_ADD, {
      ...params
    });
    yield put({
      type: Actions.GUIDE_CATEGORY_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_CATEGORY_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGuideCategoryAdd() {
  while (true) {
    const action = yield take(Actions.GUIDE_CATEGORY_ADD.POST);
    yield fork(postGuideCategoryAdd, action.params);
  }
}

export function* postGuideCategoryUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.GUIDE_CATEGORY_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.GUIDE_CATEGORY_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_CATEGORY_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGuideCategoryUpdate() {
  while (true) {
    const action = yield take(Actions.GUIDE_CATEGORY_UPDATE.POST);
    yield fork(postGuideCategoryUpdate, action.params);
  }
}

export function* postGuideCategoryDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.GUIDE_CATEGORY_DELETE, {
      ...params
    });
    yield put({
      type: Actions.GUIDE_CATEGORY_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_CATEGORY_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGuideCategoryDelete() {
  while (true) {
    const action = yield take(Actions.GUIDE_CATEGORY_DELETE.POST);
    yield fork(postGuideCategoryDelete, action.params);
  }
}
