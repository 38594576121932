import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getFeedBackList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.FEEDBACK_LIST, {
      params: params
    });
    yield put({
      type: Actions.FEEDBACK_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.FEEDBACK_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchFeedBackList() {
  while (true) {
    const action = yield take(Actions.FEEDBACK_LIST.GET);
    yield fork(getFeedBackList, action.params);
  }
}

export function* getFeedBackDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.FEEDBACK_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.FEEDBACK_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.FEEDBACK_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchFeedBackDetail() {
  while (true) {
    const action = yield take(Actions.FEEDBACK_DETAIL.GET);
    yield fork(getFeedBackDetail, action.params);
  }
}

export function* postFeedBackDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.FEEDBACK_DELETE, {
      ...params
    });
    yield put({
      type: Actions.FEEDBACK_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.FEEDBACK_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchFeedBackDelete() {
  while (true) {
    const action = yield take(Actions.FEEDBACK_DELETE.POST);
    yield fork(postFeedBackDelete, action.params);
  }
}
