import React from "react";
import { bindActionCreators } from "redux";
import { withRouter, connect } from "./core";
import Actions from "./core/net/actions";
import { IProps } from "core/interfaces/iprops";
import View from "core/components/View";
import { Switch, Route } from "react-router";
import Global from "core/env/global";
import Routers from "core/routers";
import ErrorBoundary from "core/components/ErrorBoundary";
import Login from "view/login";
import Main from "view/main";

interface IAppProps extends IProps {
  getServerTime?: () => void;
  getAreaJson?: () => void;
  serverTime?: any;
}

function mapStateToProps(state: any) {
  return {
    serverTime: state.bm[Actions.SERVER_TIME.RAW]
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      getServerTime: () => {
        return { type: Actions.SERVER_TIME.GET };
      },
      getAreaJson: () => {
        return { type: Actions.AREA_JSON.GET };
      }
    },
    dispatch
  );
}

@withRouter()
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class App extends View<IAppProps, {}> {
  state: any = {};

  constructor(props: IAppProps) {
    super(props);

    const routers: Routers = new Routers();
    Global.routers = routers;
    if (this.props.getServerTime) {
      this.props.getServerTime();
    }

    if (this.props.getAreaJson) {
      this.props.getAreaJson();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.serverTime && !props.serverTime.__processed) {
      props.serverTime.__processed = true;
      Global.serverTime = props.serverTime.data;
    }
    return null;
  }

  render() {
    return (
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route match="match" path="/main" component={Main} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

export default App;
