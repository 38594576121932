import Response from "../models/response";

class ErrorBuilder {
  public static local(e: any): Response {
    const resp = new Response();
    resp.success = false;
    resp.err_code = -2;
    resp.err_msg = e;
    resp.data = null;
    return resp;
  }

  public static network(e: any): Response {
    const resp = new Response();
    resp.success = false;
    resp.err_code = -3;
    resp.err_msg = e;
    resp.data = null;
    return resp;
  }
}

export default ErrorBuilder;
