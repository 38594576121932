import moment from "moment";
import trim from "lodash/trim";
import { notification } from "antd";

export default class Utils {
  public static toChineseDay(val: number): string {
    switch (val % 7) {
    case 0:
      return "日";
    case 1:
      return "一";
    case 2:
      return "二";
    case 3:
      return "三";
    case 4:
      return "四";
    case 5:
      return "五";
    case 6:
      return "六";
    }
    return "";
  }

  public static trimSecondsForTime(timeStr: string): string {
    return moment(moment().format("YYYY-MM-DD") + " " + timeStr).format("HH:mm");
  }

  /**
   *
   * @param dateStr format is MM:DD HH:mm:ss
   */
  public static trimSecondsForDate(dateStr: string): string {
    return moment(moment().format("YYYY-") + " " + dateStr).format("MM-DD HH:mm");
  }

  public static trimSecondsForFullDate(dateStr: string): string {
    return moment(dateStr).format("YYYY-MM-DD HH:mm");
  }

  public static trim(str: string): string {
    return trim(str);
  }
}

export function Update(that, name, params, callback = () =>{}) {
  if (that.props[name] && !that.props[name].__processed) {
    that.props[name].__processed = true;
    that.setState(
      () => ({
        loading: false
      }),
      () => {
        if (that.props[name].data !== null && !that.props[name].data.success) {
          notification.error({
            message: "错误",
            description: that.props[name].data.err_msg
          });
          return;
        }

        notification.success({
          message: "提示",
          description: "修改数据成功"
        });
      }
    );
  }
}

