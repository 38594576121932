import * as React from "react";
import { bindActionCreators } from "redux";

/* third party */

/* scripts */
import { withRouter, connect, push } from "core";

import View from "core/components/View";
import Content from "core/components/Content";
import { Icon, Menu } from "antd";
import { INavProps } from "core/interfaces/icommon_props";
import Global from "core/env/global";
import { Pages } from "core/enums/pages";
import formatMenu from "./format_menu";

interface ISlideMenuProps extends INavProps {
  push?: any;
  onCollapsed?: () => void;
}

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      push
    },
    dispatch
  );
}

@withRouter()
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class SideMenu extends View<ISlideMenuProps, {}> {
  public state: { [key: string]: any } = {
    selectedMenuKey: ["dashboard"],
    openKeys: [],
    collapsed: false
  };

  componentDidMount() {
    const segment = this.props.location.pathname.split("/");
    const openKeys = formatMenu(segment[2]);
    this.setState(() => ({
      selectedMenuKey: [segment[2]],
      openKeys
    }));
  }

  componentDidUpdate() {
    const segment = this.props.location.pathname.split("/");
    if (this.state.selectedMenuKey.toString() === [segment[2]].toString())
      return;
    this.setState(() => ({
      selectedMenuKey: [segment[2]]
    }));
  }

  public render() {
    return (
      <Content
        style={{
          position: "relative",
          backgroundColor: "#2D303C"
        }}
      >
        <Menu
          style={{ backgroundColor: "#2D303C", paddingBottom: "50px" }}
          theme={"dark"}
          selectedKeys={this.state.selectedMenuKey}
          openKeys={this.state.openKeys}
          onClick={item => {
            const routerConfig = Global.routers.getPageFullPath(
              Pages[item.key.toUpperCase()]
            );
            if (routerConfig) {
              this.props.push(routerConfig.path);
            }
          }}
          onOpenChange={openKeys => {
            this.setState(() => ({
              openKeys
            }));
          }}
          // onSelect={item => {
          //   console.log(item);
          // }}
          mode="inline"
        >
          <Menu.Item
            style={{
              margin: "0px",
              boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.15) inset"
            }}
            key="dashboard"
          >
            <Icon type="dashboard" />
            <span>概览</span>
          </Menu.Item>
          <Menu.SubMenu
            key="users"
            title={
              <span>
                <Icon type="user" />
                <span>用户管理</span>
              </span>
            }
          >
            <Menu.Item
              className="sideMenu"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
              key="users"
            >
              {/* <Icon type="user" /> */}
              所有用户
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="course_manage"
            title={
              <span>
                <Global.BMIcon type="icon-kecheng_m" />
                <span>课程管理</span>
              </span>
            }
          >
            <Menu.Item
              className="sideMenu"
              key="schedules"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
            >
              {/* <Global.BMIcon type="icon-paikeguanliicon-" /> */}
              排课管理
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              key="courses"
              style={{ margin: "0px" }}
            >
              {/* <Global.BMIcon type="icon-kecheng" /> */}
              课程信息
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="practises"
            title={
              <span>
                <Global.BMIcon type="icon-jiachang_lianxi" />
                <span>练习题管理</span>
              </span>
            }
          >
            <Menu.Item
              key="all_practises"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
              className="sideMenu"
            >
              全部练习题
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="practise_difficulties"
            >
              难度管理
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="practise_categories"
            >
              分类管理
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="tags"
            title={
              <span>
                <Icon type="tags" />
                <span>标签管理</span>
              </span>
            }
          >
            <Menu.Item
              key="practise_tags"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
              className="sideMenu"
            >
              标签库
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="tags_class"
            >
              分类管理
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="tags_grade"
            >
              难度等级
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="tags_problem"
            >
              课程问题
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="melodies"
            title={
              <span>
                <Global.BMIcon type="icon-yinfu" />
                <span>曲谱管理</span>
              </span>
            }
          >
            <Menu.Item
              className="sideMenu"
              key="all_melodies"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
            >
              全部曲谱
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              key="melody_guides"
              style={{ margin: "0px" }}
            >
              引导库
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              key="guide_category"
              style={{ margin: "0px" }}
            >
              引导分类
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              key="course_material"
              style={{ margin: "0px" }}
            >
              课程教材
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="activities">
            <Global.BMIcon type="icon-huodong1" />
            <span>活动管理</span>
          </Menu.Item>
          <Menu.Item key="orgs" style={{ margin: "0px" }}>
            <Global.BMIcon type="icon-institute" />
            <span>机构管理</span>
          </Menu.Item>
          <Menu.Item key="ads">
            <Icon type="robot" />
            <span>广告管理</span>
          </Menu.Item>
          <Menu.Item
            style={{
              margin: "0px"
            }}
            key="ai"
          >
            <Icon type="read" />
            <span>AI课程</span>
          </Menu.Item>
          <Menu.SubMenu
            key="articles"
            title={
              <span>
                <Icon type="user" />
                <span>文章管理</span>
              </span>
            }
          >
            <Menu.Item
              className="sideMenu"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
              key="articles_class"
            >
              {/* <Icon type="user" /> */}
              分类管理
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0) inset"
              }}
              key="articles"
            >
              {/* <Icon type="user" /> */}
              文章管理
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="faqs">
            <Global.BMIcon type="icon-faq" />
            <span>FAQ管理</span>
          </Menu.Item>
          <Menu.Item key="advises">
            <Icon type="project" />
            <span>意见建议</span>
          </Menu.Item>
          <Menu.Item key="solutions">
            <Global.BMIcon type="icon-jiejuefangan" />
            <span>解决方案</span>
          </Menu.Item>
          <Menu.Item key="notifications">
            <Icon type="bell" />
            <span>通知管理</span>
          </Menu.Item>
          <Menu.Item key="videos">
            <Icon type="play-square" />
            <span>视频库</span>
          </Menu.Item>
          <Menu.Item style={{ margin: "0px" }} key="audios">
            <Global.BMIcon type="icon-geshi_yinpinmp" />
            <span>音频库</span>
          </Menu.Item>
          <Menu.SubMenu
            key="system"
            title={
              <span>
                <Icon type="setting" />
                <span>系统管理</span>
              </span>
            }
          >
            <Menu.Item
              style={{
                margin: "0px",
                boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.45) inset"
              }}
              className="sideMenu"
              key="configurations"
            >
              全部配置
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="agreements"
            >
              平台协议
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="logs"
            >
              日志管理
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              style={{ margin: "0px" }}
              key="operating"
            >
              操作日志
            </Menu.Item>
            <Menu.Item
              className="sideMenu"
              key="admins"
              style={{ margin: "0px" }}
            >
              全部管理员
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        {/* <div
          style={{
            position: "fixed",
            bottom: "10px",
            height: "50px"
          }}
        >
          <Icon
            className="trigger"
            type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
            onClick={() => {
              this.setState({
                collapsed: !this.state.collapsed
              });
              if (this.props.onCollapsed) this.props.onCollapsed();
            }}
          />
        </div> */}
      </Content>
    );
  }
}

export default SideMenu;
