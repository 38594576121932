import React from 'react';
import ReactDOM from 'react-dom';
import {
  Provider
} from "react-redux";
import {
  ConnectedRouter
} from "connected-react-router";

import './index.css';
import "css/global.css";
import "reflect-metadata";
import 'braft-editor/dist/index.css'

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from "./core/store";
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';


ReactDOM.render(
  (
    <Provider store={configureStore({})}>
      <ConnectedRouter history={history}>
        <div
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          <ConfigProvider locale={zhCN}>
            <App />
          </ConfigProvider>
        </div>
      </ConnectedRouter>
    </Provider>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
