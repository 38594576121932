import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getMelodyList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.MELODY_LIST, {
      params: params
    });
    yield put({
      type: Actions.MELODY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchMelodyList() {
  while (true) {
    const action = yield take(Actions.MELODY_LIST.GET);
    yield fork(getMelodyList, action.params);
  }
}

export function* postMelodyAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_ADD, { ...params });
    yield put({
      type: Actions.MELODY_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyAdd() {
  while (true) {
    const action = yield take(Actions.MELODY_ADD.POST);
    yield fork(postMelodyAdd, action.params);
  }
}

export function* postMelodyUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_UPDATE, { ...params });
    yield put({
      type: Actions.MELODY_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyUpdate() {
  while (true) {
    const action = yield take(Actions.MELODY_UPDATE.POST);
    yield fork(postMelodyUpdate, action.params);
  }
}

export function* postMelodyDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_DELETE, { ...params });
    yield put({
      type: Actions.MELODY_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyDelete() {
  while (true) {
    const action = yield take(Actions.MELODY_DELETE.POST);
    yield fork(postMelodyDelete, action.params);
  }
}


export function* getMelodyDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.MELODY_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.MELODY_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchMelodyDetail() {
  while (true) {
    const action = yield take(Actions.MELODY_DETAIL.GET);
    yield fork(getMelodyDetail, action.params);
  }
}


export function* getMelodyGuideDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.MELODY_GUIDE_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.MELODY_GUIDE_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_GUIDE_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchMelodyGuideDetail() {
  while (true) {
    const action = yield take(Actions.MELODY_GUIDE_DETAIL.GET);
    yield fork(getMelodyGuideDetail, action.params);
  }
}


export function* postMelodyGuideDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_GUIDE_DELETE, { ...params });
    yield put({
      type: Actions.MELODY_GUIDE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_GUIDE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyGuideDelete() {
  while (true) {
    const action = yield take(Actions.MELODY_GUIDE_DELETE.POST);
    yield fork(postMelodyGuideDelete, action.params);
  }
}



export function* postGuideDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.GUIDE_DELETE, { ...params });
    yield put({
      type: Actions.GUIDE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGuideDelete() {
  while (true) {
    const action = yield take(Actions.GUIDE_DELETE.POST);
    yield fork(postGuideDelete, action.params);
  }
}



export function* getGuideDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.GUIDE_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.GUIDE_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.GUIDE_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchGuideDetail() {
  while (true) {
    const action = yield take(Actions.GUIDE_DETAIL.GET);
    yield fork(getGuideDetail, action.params);
  }
}


export function* postMelodyGuideAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_GUIDE_ADD, { ...params });
    yield put({
      type: Actions.MELODY_GUIDE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_GUIDE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyGuideAdd() {
  while (true) {
    const action = yield take(Actions.MELODY_GUIDE_ADD.POST);
    yield fork(postMelodyGuideAdd, action.params);
  }
}

export function* postMelodyGuideUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_GUIDE_UPDATE, { ...params });
    yield put({
      type: Actions.MELODY_GUIDE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_GUIDE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyGuideUpdate() {
  while (true) {
    const action = yield take(Actions.MELODY_GUIDE_UPDATE.POST);
    yield fork(postMelodyGuideUpdate, action.params);
  }
}





export function* postMelodyImageDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_IMAGE_DELETE, { ...params });
    yield put({
      type: Actions.MELODY_IMAGE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_IMAGE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyImageDelete() {
  while (true) {
    const action = yield take(Actions.MELODY_IMAGE_DELETE.POST);
    yield fork(postMelodyImageDelete, action.params);
  }
}


export function* postMelodyImageUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_IMAGE_UPDATE, { ...params });
    yield put({
      type: Actions.MELODY_IMAGE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_IMAGE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyImageUpdate() {
  while (true) {
    const action = yield take(Actions.MELODY_IMAGE_UPDATE.POST);
    yield fork(postMelodyImageUpdate, action.params);
  }
}


export function* postMelodyImageAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MELODY_IMAGE_ADD, { ...params });
    yield put({
      type: Actions.MELODY_IMAGE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MELODY_IMAGE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchMelodyImageAdd() {
  while (true) {
    const action = yield take(Actions.MELODY_IMAGE_ADD.POST);
    yield fork(postMelodyImageAdd, action.params);
  }
}