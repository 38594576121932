import Model from "./model";
import { Type } from "class-transformer";
import User from "./user";
import Organization from "./organization";

export default class Admin extends Model {
    public realname?: string;
    public avatar?: string; 
    public token?: string;
    public telephone?: string;
    public password?: string;
    public remark?: string;
    public role_id?: number;
    public is_locked?: number;
    public teacher_id?: string;
    public org_id?: string;
    
    @Type(() => User)
    public teacher?: User;

    @Type(() => Organization)
    public org?: Organization;

}