import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getSystemAgrList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SYSTEM_AGR_LIST, { params: params });
    yield put({
      type: Actions.SYSTEM_AGR_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_AGR_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSystemAgrList() {
  while (true) {
    const action = yield take(Actions.SYSTEM_AGR_LIST.GET);
    yield fork(getSystemAgrList, action.params);
  }
}


export function* getSystemAgrDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SYSTEM_AGR_DETAIL, { params: params });
    yield put({
      type: Actions.SYSTEM_AGR_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_AGR_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSystemAgrDetail() {
  while (true) {
    const action = yield take(Actions.SYSTEM_AGR_DETAIL.GET);
    yield fork(getSystemAgrDetail, action.params);
  }
}







export function* postSystemGarAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SYSTEM_AGR_ADD, {
      ...params
    });
    yield put({
      type: Actions.SYSTEM_AGR_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_AGR_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSystemGarAdd() {
  while (true) {
    const action = yield take(Actions.SYSTEM_AGR_ADD.POST);
    yield fork(postSystemGarAdd, action.params);
  }
}



export function* postSystemGarUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SYSTEM_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.SYSTEM_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSystemGarUpdate() {
  while (true) {
    const action = yield take(Actions.SYSTEM_UPDATE.POST);
    yield fork(postSystemGarUpdate, action.params);
  }
}

export function* getSystemDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SYSTEM_DETAIL, { params: params });
    yield put({
      type: Actions.SYSTEM_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSystemDetail() {
  while (true) {
    const action = yield take(Actions.SYSTEM_DETAIL.GET);
    yield fork(getSystemDetail, action.params);
  }
}


export function* getSystemOperList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SYSTEM_OPER_LIST, { params: params });
    yield put({
      type: Actions.SYSTEM_OPER_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_OPER_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSystemOperList() {
  while (true) {
    const action = yield take(Actions.SYSTEM_OPER_LIST.GET);
    yield fork(getSystemOperList, action.params);
  }
}




export function* postSystemDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SYSTEM_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SYSTEM_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSystemDelete() {
  while (true) {
    const action = yield take(Actions.SYSTEM_DELETE.POST);
    yield fork(postSystemDelete, action.params);
  }
}


export function* postSystemOperRestore(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SYSTEM_OPER_RESTORE, {
      ...params
    });
    yield put({
      type: Actions.SYSTEM_OPER_RESTORE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_OPER_RESTORE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSystemOperRestore() {
  while (true) {
    const action = yield take(Actions.SYSTEM_OPER_RESTORE.POST);
    yield fork(postSystemOperRestore, action.params);
  }
}





export function* postSystemOperRollback(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SYSTEM_OPER_ROLLBACK, {
      ...params
    });
    yield put({
      type: Actions.SYSTEM_OPER_ROLLBACK.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SYSTEM_OPER_ROLLBACK.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSystemOperRollback() {
  while (true) {
    const action = yield take(Actions.SYSTEM_OPER_ROLLBACK.POST);
    yield fork(postSystemOperRollback, action.params);
  }
}