import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";


export function* postLiveSig(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.LIVE_SIG, {
      ...params
    });
    yield put({
      type: Actions.LIVE_SIG.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.LIVE_SIG.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLiveSig() {
  while (true) {
    const action = yield take(Actions.LIVE_SIG.POST);
    yield fork(postLiveSig, action.params);
  }
}
