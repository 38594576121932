import * as React from "react";
import View from "./View";
import { IStyleProps } from "../interfaces/icommon_props";

interface ILogoProps extends IStyleProps {
  shadowVisible?: boolean;
}

class Logo extends View<ILogoProps, {}> {
  public static defaultProps: any;

  constructor(props: ILogoProps) {
    super(props);

    Logo.defaultProps = {
      shadowVisible: false
    };
  }

  public render() {
    const styles = {
      background: `url(${require("../../assets/logo.svg")}) no-repeat center center / 100% 100%`,
      ...this.props.style
    };

    if (this.props.shadowVisible) {
      styles["boxShadow"] = "rgba(0, 0, 0, 0.1) 4px 4px 0px";
    }

    return (
      <div
        className={`bm-logo-circle-medium ${this.props.className}`}
        style={styles}
      />
    );
  }
}

export default Logo;
