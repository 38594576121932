import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";
//文章列表
export function* getAticleList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ARTICLE_LIST, {
      params: params
    });
    yield put({
      type: Actions.ARTICLE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAticleList() {
  while (true) {
    const action = yield take(Actions.ARTICLE_LIST.GET);
    yield fork(getAticleList, action.params);
  }
}


//文章详情
export function* getAticleDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ARTICLE_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.ARTICLE_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAticleDetail() {
  while (true) {
    const action = yield take(Actions.ARTICLE_DETAIL.GET);
    yield fork(getAticleDetail, action.params);
  }
}


//文章分类列表 article/categories/list
export function* getAticleCategoriesList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ARTICLE_CATEGORIES_LIST, {
      params: params
    });
    yield put({
      type: Actions.ARTICLE_CATEGORIES_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_CATEGORIES_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAticleCategoriesList() {
  while (true) {
    const action = yield take(Actions.ARTICLE_CATEGORIES_LIST.GET);
    yield fork(getAticleCategoriesList, action.params);
  }
}



//文章修改 article/update
export function* postArticleUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ARTICLE_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.ARTICLE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchArticleUpdate() {
  while (true) {
    const action = yield take(Actions.ARTICLE_UPDATE.POST);
    yield fork(postArticleUpdate, action.params);
  }
}

//文章分类修改name article/categories/update
export function* postArticleCategoriesUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ARTICLE_CATEGORIES_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.ARTICLE_CATEGORIES_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_CATEGORIES_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchArticleCategoriesUpdate() {
  while (true) {
    const action = yield take(Actions.ARTICLE_CATEGORIES_UPDATE.POST);
    yield fork(postArticleCategoriesUpdate, action.params);
  }
}

//文章分类删除   article/categories/delete
export function* postArticleCategoriesDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ARTICLE_CATEGORIES_DELETE, {
      ...params
    });
    yield put({
      type: Actions.ARTICLE_CATEGORIES_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_CATEGORIES_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchArticleCategoriesDelete() {
  while (true) {
    const action = yield take(Actions.ARTICLE_CATEGORIES_DELETE.POST);
    yield fork(postArticleCategoriesDelete, action.params);
  }
}


//文章分类新增   article/categories/add
export function* postArticleCategoriesAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ARTICLE_CATEGORIES_ADD, {
      ...params
    });
    yield put({
      type: Actions.ARTICLE_CATEGORIES_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_CATEGORIES_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchArticleCategoriesAdd() {
  while (true) {
    const action = yield take(Actions.ARTICLE_CATEGORIES_ADD.POST);
    yield fork(postArticleCategoriesAdd, action.params);
  }
}


//文章分类详情   article/categories/detail


export function* getArticleCategoriesDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ARTICLE_CATEGORIES_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.ARTICLE_CATEGORIES_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_CATEGORIES_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchArticleCategoriesDetail() {
  while (true) {
    const action = yield take(Actions.ARTICLE_CATEGORIES_DETAIL.GET);
    yield fork(getArticleCategoriesDetail, action.params);
  }
}



//文章删除   article/delete
export function* postArticleDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ARTICLE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.ARTICLE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchArticleDelete() {
  while (true) {
    const action = yield take(Actions.ARTICLE_DELETE.POST);
    yield fork(postArticleDelete, action.params);
  }
}


//文章新增  article/add
export function* postArticleAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ARTICLE_ADD, {
      ...params
    });
    yield put({
      type: Actions.ARTICLE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ARTICLE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchArticleAdd() {
  while (true) {
    const action = yield take(Actions.ARTICLE_ADD.POST);
    yield fork(postArticleAdd, action.params);
  }
}