import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getAudioList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AUDIO_LIST, { params: params });
    yield put({
      type: Actions.AUDIO_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AUDIO_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAudioList() {
  while (true) {
    const action = yield take(Actions.AUDIO_LIST.GET);
    yield fork(getAudioList, action.params);
  }
}


export function* getAudioDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AUDIO_DETAIL, { params: params });
    yield put({
      type: Actions.AUDIO_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AUDIO_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAudioDetail() {
  while (true) {
    const action = yield take(Actions.AUDIO_DETAIL.GET);
    yield fork(getAudioDetail, action.params);
  }
}



export function* postAudioUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AUDIO_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AUDIO_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AUDIO_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAudioUpdate() {
  while (true) {
    const action = yield take(Actions.AUDIO_UPDATE.POST);
    yield fork(postAudioUpdate, action.params);
  }
}

export function* postAudioDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AUDIO_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AUDIO_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AUDIO_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAudioDelete() {
  while (true) {
    const action = yield take(Actions.AUDIO_DELETE.POST);
    yield fork(postAudioDelete, action.params);
  }
}

export function* postAudioAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AUDIO_ADD, {
      ...params
    });
    yield put({
      type: Actions.AUDIO_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AUDIO_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAudioAdd() {
  while (true) {
    const action = yield take(Actions.AUDIO_ADD.POST);
    yield fork(postAudioAdd, action.params);
  }
}
