import Action from "../models/action";

class Actions {
    public static SERVER_TIME                               : Action            = new Action("server_time");
    public static AREA_JSON                                 : Action            = new Action('area_json');

    public static LOGIN                                     : Action            = new Action("login");

    public static USER_LIST                                 : Action            = new Action("user_list");
    public static USER_DETAIL                               : Action            = new Action("user_detail");
    public static USER_CREATE                               : Action            = new Action("user_create");
    public static USER_DELETE                               : Action            = new Action("user_delete");
    public static USER_UPDATE                               : Action            = new Action('user_update');
    public static USER_GEN_STUDENT                          : Action            = new Action('user_gen_student');
    public static USER_GEN_TEACHER                          : Action            = new Action('user_gen_teacher');
    public static USER_MAIN_TEACHER_BATCH_BIND              : Action            = new Action('user_main_teacher_batch_bind');
    public static USER_HOMEWORK_LIST                        : Action            = new Action('user_homework_list');
    public static USER_HOMEWORK_STEPS                       : Action            = new Action('user_homework_steps');
    public static USER_HOMEWORK_SUBJECTS                    : Action            = new Action('user_homework_subjects');
    public static HOMEWORK_REVIEW_DELETE                    : Action            = new Action('homework_review_delete');
    public static HOMEWORK_REVIEW_SUBMIT                    : Action            = new Action('homework_review_submit');
    public static HOMEWORK_REVIEW_LIST                      : Action            = new Action('homework_review_list');
    public static USER_UPDATE_USER_COMPONENT                : Action            = new Action('user_update_user_component');
    public static USER_USER_COMPONENT                       : Action            = new Action('user_user_component');
    public static USER_HOMEWORK_ANSWER                      : Action            = new Action('user_homework_answer');



    public static COMPONENT_LIST                            : Action            = new Action('component_list');




    
    public static ADD_SCHEDULE                              : Action            = new Action("add_schedule");
    public static UPDATE_SCHEDULE                           : Action            = new Action("update_schedule");
    public static SCHEDULE_LIST                             : Action            = new Action("schedule_list");
    public static SCHEDULE_DELETE                           : Action            = new Action("schedule_delete");
    public static SCHEDULE_SELECT                             : Action            = new Action("schedule_course_select");

    public static COURSES                                   : Action            = new Action("courses_list");
    public static ADJUST_COURSE                             : Action            = new Action("adjust_course");
    public static ABSENT_LIST                               : Action            = new Action("absent_list");
    public static MANUAL_FINISH                             : Action            = new Action("manual_finish");

    public static ADMIN_LIST                                : Action            = new Action("system_admin_list");
    public static ADMIN_ADD                                 : Action            = new Action("system_admin_add");
    public static ADMIN_UPDATE                              : Action            = new Action("system_admin_update");
    public static ADMIN_DELETE                              : Action            = new Action("system_admin_delete");
    public static ADMIN_LOGS                                : Action            = new Action("system_admin_logs");

    public static LOG_LIST                                  : Action            = new Action("log_list");
    public static LOG_DELETE                                : Action            = new Action("log_delete");

    public static ORG_LIST                                  : Action            = new Action("org_list");
    public static ORG_DETAIL                                : Action            = new Action("org_detail");
    public static ORG_CREATE                                : Action            = new Action("org_create");
    public static ORG_UPDATE                                : Action            = new Action("org_update");
    public static ORG_DELETE                                : Action            = new Action("org_delete");
    public static ORG_SETTING_DETAIL                        : Action            = new Action("org_setting_detail");
    public static ORG_SETTING_UPDATE                        : Action            = new Action("org_setting_update");

    public static GUIDE_LIST                                : Action            = new Action("guide_list");
    public static GUIDE_ADD                                 : Action            = new Action("guide_add");
    public static GUIDE_UPDATE                              : Action            = new Action("guide_update");
    public static GUIDE_DELETE                              : Action            = new Action("guide_delete");
    public static GUIDE_DETAIL                              : Action            = new Action("guide_detail");

    public static MELODY_GUIDE_DETAIL                       : Action            = new Action("melody_guide_detail");
    public static MELODY_GUIDE_DELETE                       : Action            = new Action("melody_guide_delete");
    public static MELODY_GUIDE_ADD                          : Action            = new Action("melody_guide_add");
    public static MELODY_GUIDE_UPDATE                       : Action            = new Action("melody_guide_update");
    




    public static GUIDE_CATEGORY_LIST                       : Action            = new Action("guide_category_list");
    public static GUIDE_CATEGORY_ADD                        : Action            = new Action("guide_category_add");
    public static GUIDE_CATEGORY_UPDATE                     : Action            = new Action("guide_category_update");
    public static GUIDE_CATEGORY_DELETE                     : Action            = new Action("guide_category_delete");

    public static MELODY_LIST                               : Action            = new Action("melody_list");
    public static MELODY_ADD                                : Action            = new Action("melody_add");
    public static MELODY_UPDATE                             : Action            = new Action("melody_update");
    public static MELODY_DELETE                             : Action            = new Action("melody_delete");
    public static MELODY_DETAIL                             : Action            = new Action("melody_detail");

    public static MELODY_IMAGE_DELETE                       : Action            = new Action
    ("melody_image_delete");
    public static MELODY_IMAGE_UPDATE                       : Action            = new Action
    ("melody_image_update");
    public static MELODY_IMAGE_ADD                          : Action            = new Action
    ("melody_image_add");

    



    public static TEXTBOOK_LIST                             : Action            = new Action("textbook_list");
    public static TEXTBOOK_ADD                              : Action            = new Action("textbook_add");
    public static TEXTBOOK_UPDATE                           : Action            = new Action("textbook_update");
    public static TEXTBOOK_DELETE                           : Action            = new Action("textbook_delete");

    public static TEXTBOOK_CHAPTER_LIST                     : Action            = new Action("textbook_chapter_list");
    public static TEXTBOOK_CHAPTER_ADD                      : Action            = new Action("textbook_chapter_add");
    public static TEXTBOOK_CHAPTER_UPDATE                   : Action            = new Action("textbook_chapter_update");
    public static TEXTBOOK_CHAPTER_DELETE                   : Action            = new Action("textbook_chapter_delete");

    public static TEXTBOOK_LESSON_LIST                      : Action            = new Action("textbook_lesson_list");
    public static TEXTBOOK_LESSON_ADD                       : Action            = new Action("textbook_lesson_add");
    public static TEXTBOOK_LESSON_UPDATE                    : Action            = new Action("textbook_lesson_update");
    public static TEXTBOOK_LESSON_DELETE                    : Action            = new Action("textbook_lesson_delete");

    public static SUBJECT_LIST                              : Action            = new Action("subject_list");
    public static SUBJECT_DETAIL                            : Action            = new Action("subject_detail");
    public static SUBJECT_SAVE                              : Action            = new Action("subject_save");
    public static SUBJECT_UPDATE                            : Action            = new Action("subject_update");
    public static SUBJECT_DELETE                            : Action            = new Action("subject_delete");

    public static SUBJECT_CATEGORY_LIST                     : Action            = new Action("subject_category_list");
    public static SUBJECT_CATEGORY_DETAIL                   : Action            = new Action("subject_category_detail");
    public static SUBJECT_CATEGORY_SAVE                     : Action            = new Action("subject_category_save");
    public static SUBJECT_CATEGORY_UPDATE                   : Action            = new Action("subject_category_update");
    public static SUBJECT_CATEGORY_DELETE                   : Action            = new Action("subject_category_delete");
    public static SUBJECT_CATEGORY_TAGSLIST                 : Action            = new Action("subject_category_tags_list"); 

    public static SUBJECT_DIFFCULTY_LIST                    : Action            = new Action("subject_difficulty_list");
    public static SUBJECT_DIFFCULTY_DETAIL                  : Action            = new Action("subject_difficulty_detail");
    public static SUBJECT_DIFFCULTY_SAVE                    : Action            = new Action("subject_difficulty_save");
    public static SUBJECT_DIFFCULTY_UPDATE                  : Action            = new Action("subject_difficulty_update");
    public static SUBJECT_DIFFCULTY_DELETE                  : Action            = new Action("subject_difficulty_delete");

    public static SUBJECT_TAGS_LIST                         : Action            = new Action("subject_tags_list");    
    public static SUBJECT_TAGS_DETAIL                       : Action            = new Action("subject_tags_detail");  
    public static SUBJECT_TAGS_SAVE                         : Action            = new Action("subject_tags_save");    
    public static SUBJECT_TAGS_UPDATE                       : Action            = new Action("subject_tags_update");  
    public static SUBJECT_TAGS_DELETE                       : Action            = new Action("subject_tags_delete"); 


    public static TAGS_LIST                                 : Action            = new Action("tags_list");    
    public static TAGS_ADD                                  : Action            = new Action("tags_add");    
    public static TAGS_UPDATE                               : Action            = new Action("tags_update");  
    public static TAGS_DELETE                               : Action            = new Action("tags_delete"); 

    public static TAGS_CATEGORY_DELETE                      : Action            = new Action("tags_category_delete"); 
    public static TAGS_CATEGORY_UPDATE                      : Action            = new Action("tags_category_update"); 
    public static TAGS_CATEGORY_LIST                        : Action            = new Action("tags_category_list"); 
    public static TAGS_CATEGORY_ADD                         : Action            = new Action("tags_category_add"); 


    public static  TAGS_PROBLEM_DELETE                      : Action            = new Action("tags_problem_delete"); 
    public static  TAGS_PROBLEM_UPDATE                      : Action            = new Action("tags_problem_update"); 
    public static  TAGS_PROBLEM_ADD                         : Action            = new Action("tags_problem_add"); 
    public static  TAGS_PROBLEM_LIST                        : Action            = new Action("tags_problem_list"); 



    public static  TAGS_LEVEL_DELETE                        : Action            = new Action("tags_level_delete"); 
    public static  TAGS_LEVEL_UPDATE                        : Action            = new Action("tags_level_update"); 
    public static  TAGS_LEVEL_ADD                           : Action            = new Action("tags_level_add"); 
    public static  TAGS_LEVEL_LIST                          : Action            = new Action("tags_level_list"); 





    public static ADS_LIST                                  : Action            = new Action("ads_list");    
    public static ADS_DETAIL                                : Action            = new Action("ads_detail");  
    public static ADS_UPDATE                                : Action            = new Action("ads_update");    
    public static ADS_SAVE                                  : Action            = new Action("ads_save");  
    public static ADS_DELETE                                : Action            = new Action("ads_delete"); 

    public static FAQ_LIST                                  : Action            = new Action("faq_list");
    public static FAQ_DETAIL                                : Action            = new Action("faq_detail");
    public static FAQ_ADD                                   : Action            = new Action("faq_add");
    public static FAQ_UPDATE                                : Action            = new Action("faq_update");
    public static FAQ_DELETE                                : Action            = new Action("faq_delete");

    public static FEEDBACK_LIST                             : Action            = new Action("feedback_list");
    public static FEEDBACK_DETAIL                           : Action            = new Action("feedback_detail");
    public static FEEDBACK_DELETE                           : Action            = new Action("feedback_delete");

    public static SOLUTION_LIST                             : Action            = new Action("solution_list");    
    public static SOLUTION_ADD                              : Action            = new Action("solution_add");      
    public static SOLUTION_UPDATE                           : Action            = new Action("solution_update");      
    public static SOLUTION_DETAIL                           : Action            = new Action("solution_detail");      
    public static SOLUTION_DELETE                           : Action            = new Action("solution_delete");      
    
    public static VOD_PLAYABLE                              : Action            = new Action("vod_playable");

    // local
    public static RELOAD_COURSES                            : Action            = new Action("reload_courses");

    public static VOD_SIG                                   : Action            = new Action("vod_sig");
    public static OSS_STS                                   : Action            = new Action("oss_sts");
    public static STS                                       : Action            = new Action('oss_sts');
    
    //新增课程
    public static SCHEDULE_INSERT                           : Action            = new Action("schedule_course_insertCourse");
    public static SCHEDULE_COURES_SCHEDULE_TYPE             : Action            = new Action("schedule_course_schedule_type");
    public static SCHEDULE_COURES_AI_COURSE                 : Action            = new Action("schedule_course_ai_course");

    //通知
    public static NOTIFICATION_DELETE                       : Action            = new Action("notification_delete");
    public static NOTIFICATION_DETAIL                       : Action            = new Action("notification_detail");
    public static NOTIFICATION_LIST                         : Action            = new Action("notification_list");
    public static NOTIFICATION_ADD                          : Action            = new Action("notification_add");
    public static NOTIFICATION_UPDATE                       : Action            = new Action("notification_update");
    public static NOTIFICATION_CLIENT                       : Action            = new Action("notification_client");
    
    // ai
    public static AI_EVALUATION_LIST                       : Action            = new Action("ai_evaluation_list");
    public static AI_WRONG_LIST                            : Action            = new Action("ai_wrong_list");
    public static AI_COURSE_INIT                           : Action            = new Action("ai_course_init");
    public static AI_LIST                                  : Action            = new Action("ai_list");
    public static AI_AI_LIST                               : Action            = new Action("ai_ai_list");
    public static AI_COURSE_TIPS                           : Action            = new Action("ai_course_tips");
    public static AI_WRONG_ADD                             : Action            = new Action("ai_wrong_add");
    public static AI_EVALUATION_DETAIL                     : Action            = new Action("ai_evaluation_detail");
    public static AI_DETAIL                                : Action            = new Action("ai_detail");
    public static AI_COURSE_SKIP                           : Action            = new Action("ai_course_skip");
    public static AI_COURSE_START                          : Action            = new Action("ai_course_start");
    public static AI_COURSE_MARK                           : Action            = new Action("ai_course_mark");
    public static AI_COURSE_WRONG_ITEMS                    : Action            = new Action("ai_course_wrong_items");
    public static AI_COURSE_CONTINUE                       : Action            = new Action("ai_course_continue");
    public static AI_COURSE_FINISH                         : Action            = new Action("ai_course_finish");
    public static AI_EVALUATION_UPDATE                     : Action            = new Action("ai_course_update");
    public static AI_UPDATE                                : Action            = new Action("ai_update");
    public static AI_WRONG_UPDATE                          : Action            = new Action("ai_wrong_update");
    public static AI_EVALUATION_DELETE                     : Action            = new Action("ai_evaluation_delete");
    public static AI_WRONG_DELETE                          : Action            = new Action("ai_wrong_delete");
    public static AI_COURSE_SUBJECT_CHOICES                : Action            = new Action("ai_course_subject_choices");
    public static AI_ADD                                   : Action            = new Action("ai_add");
    public static AI_EVALUATION_ADD                        : Action            = new Action("ai_evaluation_add");
    public static AI_WRONG_DETAIL                          : Action            = new Action("ai_wrong_detail");
    public static AI_DELETE                                : Action            = new Action("ai_delete");
    public static AI_SEGMENT_LIST                          : Action            = new Action("ai_segment_list");
    public static AI_SEGMENT_ADD                           : Action            = new Action("ai_segment_add");
    public static AI_CHOICE_UPDATE                         : Action            = new Action("ai_choice_update");
    public static AI_CHOICE_DELETE                         : Action            = new Action("ai_choice_delete");
    public static AI_CHOICE_ADD                            : Action            = new Action("ai_choice_add");
    public static AI_SEGMENT_UPDATE                        : Action            = new Action("ai_segment_update");
    public static AI_SEGMENT_DELETE                        : Action            = new Action("ai_segment_delete");
    public static AI_OPTION_UPDATE                         : Action            = new Action("ai_option_update");
    public static AI_CHOICE_LIST                           : Action            = new Action("ai_choice_list");
    public static AI_OPTION_DELETE                         : Action            = new Action("ai_option_delete");
    public static AI_OPTION_ADD                            : Action            = new Action("ai_option_add");
    public static AI_CHOICE_DETAIL                         : Action            = new Action("ai_choice_detail");





    //练习题
    public static SUBJECT_COMMENT_LIST                     : Action            = new Action("subject_comment_list");
    public static SUBJECT_COMMENT_UPDATE                   : Action            = new Action("subject_comment_update");
    public static SUBJECT_COMMENT_DETAIL                   : Action            = new Action("subject_comment_detail");
    public static SUBJECT_COMMENT_DELETE                   : Action            = new Action("subject_comment_delete");

    //文章
    public static ARTICLE_CATEGORIES_LIST                  : Action            = new Action("article_categories_list");
    public static ARTICLE_LIST                             : Action            = new Action("article_list");
    public static ARTICLE_UPDATE                           : Action            = new Action("article_update");
    public static ARTICLE_CATEGORIES_UPDATE                : Action            = new Action("article_categories_update");
    public static ARTICLE_CATEGORIES_DELETE                : Action            = new Action("article_categories_delete");
    public static ARTICLE_CATEGORIES_ADD                   : Action            = new Action("article_categories_add");
    public static ARTICLE_CATEGORIES_DETAIL                : Action            = new Action("article_categories_detail");
    public static ARTICLE_DELETE                           : Action            = new Action("article_delete");
    public static ARTICLE_ADD                              : Action            = new Action("article_add");
    public static ARTICLE_DETAIL                           : Action            = new Action("article_detail");

    //平台协议
    public static SYSTEM_AGR_LIST                           : Action            = new Action("system_agr_list");
    public static SYSTEM_AGR_ADD                            : Action            = new Action("system_agr_add");
    public static SYSTEM_AGR_DETAIL                         : Action            = new Action("system_agr_detail");
    public static SYSTEM_DETAIL                             : Action            = new Action("system_detail");
    public static SYSTEM_UPDATE                             : Action            = new Action("system_update");

    public static SYSTEM_OPER_ROLLBACK                      : Action            = new Action("system_oper_rollback");
    public static SYSTEM_OPER_RESTORE                       : Action            = new Action("system_oper_restore");
    public static SYSTEM_OPER_LIST                          : Action            = new Action("system_oper_list");
    public static SYSTEM_DELETE                             : Action            = new Action("system_delete");



    public static USER_SCHEDULE_LIST                         : Action            = new Action("user_schedule_list");


    //活动
    public static ACTIVITY_LIST                             : Action            = new Action("activity_list");
    public static ACTIVITY_DELETE                           : Action            = new Action("activity_delete");
    public static ACTIVITY_DETAIL                           : Action            = new Action("activity_detail");
    public static ACTIVITY_SAVE                             : Action            = new Action("activity_save");

    //创建腾讯直播房间
    public static LIVE_SIG                                  : Action            = new Action("live_sig");

    public static USER_TASK                                  : Action            = new Action("user_task");

    public static AUDIO_LIST                                : Action            = new Action("audio_list");
    public static AUDIO_DETAIL                              : Action            = new Action("audio_detail");
    public static AUDIO_UPDATE                              : Action            = new Action("audio_update");
    public static AUDIO_DELETE                              : Action            = new Action("audio_delete");
    public static AUDIO_ADD                                 : Action            = new Action("audio_add");

    public static VIDEO_LIST                                : Action            = new Action("video_list");
    public static VIDEO_DELETE                              : Action            = new Action("video_delete");
    public static VIDEO_UPDATE                              : Action            = new Action("video_update");
    public static VIDEO_CREATE                              : Action            = new Action("video_create");
    public static VIDEO_DETAIL                              : Action            = new Action("video_detail");


    public static PUB_METRONOME                             : Action            = new Action("pub_metronome");

    public static TICKET                                    : Action            = new Action("ticket");

    public static COURSE_HAS                                : Action            = new Action("course_has");

    //整体
    public static BASIS_SCHEDULE                            : Action            = new Action("basis_schedule");
    public static BASIS_USER                                : Action            = new Action("basis_user");
    public static BASIS_COURSE                              : Action            = new Action("basis_course");
    public static BASIS_HUIKE                               : Action            = new Action("basis_huike");
    public static BASIS_BASIS                               : Action            = new Action("basis_basis");

    // 音频
    public static AI_AUIDO_ADD                               : Action            = new Action("ai_audio_add");
    public static AI_AUDIO_DELETE                            : Action            = new Action("ai_audio_delete");
    public static AI_AUDIO_UPDATE                            : Action            = new Action("ai_audio_update");
}

export default Actions;