import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getSubjectList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_LIST, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectList() {
  while (true) {
    const action = yield take(Actions.SUBJECT_LIST.GET);
    yield fork(getSubjectList, action.params);
  }
}

export function* getSubjectDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectDetail() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DETAIL.GET);
    yield fork(getSubjectDetail, action.params);
  }
}

export function* postSubjectAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_SAVE, { ...params });
    yield put({
      type: Actions.SUBJECT_SAVE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_SAVE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectAdd() {
  while (true) {
    const action = yield take(Actions.SUBJECT_SAVE.POST);
    yield fork(postSubjectAdd, action.params);
  }
}

export function* postSubjectUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectUpdate() {
  while (true) {
    const action = yield take(Actions.SUBJECT_UPDATE.POST);
    yield fork(postSubjectUpdate, action.params);
  }
}

export function* postSubjectDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectDelete() {
  while (true) {
    const action = yield take(Actions.SUBJECT_DELETE.POST);
    yield fork(postSubjectDelete, action.params);
  }
}
