import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getOrgList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ORG_LIST, { params: params });
    yield put({
      type: Actions.ORG_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchOrgList() {
  while (true) {
    const action = yield take(Actions.ORG_LIST.GET);
    yield fork(getOrgList, action.params);
  }
}

export function* getOrgDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ORG_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.ORG_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchOrgDetail() {
  while (true) {
    const action = yield take(Actions.ORG_DETAIL.GET);
    yield fork(getOrgDetail, action.params);
  }
}

export function* postOrgCreate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ORG_CREATE, { ...params });
    yield put({
      type: Actions.ORG_CREATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_CREATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchpostOrgCreate() {
  while (true) {
    const action = yield take(Actions.ORG_CREATE.POST);
    yield fork(postOrgCreate, action.params);
  }
}

export function* postOrgUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ORG_UPDATE, { ...params });
    yield put({
      type: Actions.ORG_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchOrgUpdate() {
  while (true) {
    const action = yield take(Actions.ORG_UPDATE.POST);
    yield fork(postOrgUpdate, action.params);
  }
}

export function* postOrgDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ORG_DELETE, { ...params });
    yield put({
      type: Actions.ORG_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchOrgDelete() {
  while (true) {
    const action = yield take(Actions.ORG_DELETE.POST);
    yield fork(postOrgDelete, action.params);
  }
}




// org/setting/detail 机构配置
export function* getOrgSettingDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ORG_SETTING_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.ORG_SETTING_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_SETTING_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchOrgSettingDetail() {
  while (true) {
    const action = yield take(Actions.ORG_SETTING_DETAIL.GET);
    yield fork(getOrgSettingDetail, action.params);
  }
}


// 机构配置修改 org/setting/update
export function* postOrgSettingUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ORG_SETTING_UPDATE, { ...params });
    yield put({
      type: Actions.ORG_SETTING_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ORG_SETTING_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchOrgSettingUpdate() {
  while (true) {
    const action = yield take(Actions.ORG_SETTING_UPDATE.POST);
    yield fork(postOrgSettingUpdate, action.params);
  }
}