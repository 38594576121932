import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getAdsList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ADS_LIST, { params: params });
    yield put({
      type: Actions.ADS_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ADS_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAdsList() {
  while (true) {
    const action = yield take(Actions.ADS_LIST.GET);
    yield fork(getAdsList, action.params);
  }
}

export function* getAdsDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ADS_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.ADS_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ADS_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAdsDetail() {
  while (true) {
    const action = yield take(Actions.ADS_DETAIL.GET);
    yield fork(getAdsDetail, action.params);
  }
}

export function* postAdsAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADS_SAVE, { ...params });
    yield put({
      type: Actions.ADS_SAVE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADS_SAVE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdsAdd() {
  while (true) {
    const action = yield take(Actions.ADS_SAVE.POST);
    yield fork(postAdsAdd, action.params);
  }
}

export function* postAdsUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADS_UPDATE, { ...params });
    yield put({
      type: Actions.ADS_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADS_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdsUpdate() {
  while (true) {
    const action = yield take(Actions.ADS_UPDATE.POST);
    yield fork(postAdsUpdate, action.params);
  }
}

export function* postAdsDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADS_DELETE, { ...params });
    yield put({
      type: Actions.ADS_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADS_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdsDelete() {
  while (true) {
    const action = yield take(Actions.ADS_DELETE.POST);
    yield fork(postAdsDelete, action.params);
  }
}
