class Log {
  public static verbose(...v: any[]) {
    console.log("%c[Verbose]:", "font-weight:bold;");
    console.log(...v);
  }

  public static info(...info: any[]) {
    console.log("%c[Info]:", "color:#00bb1a;font-weight:bold;");
    console.log(...info);
  }

  public static error(...msg: any[]) {
    console.log("%c[Error]:", "color:#ce0000;font-weight:bold;");
    console.log(...msg);
  }

  public static warn(...msg: any[]) {
    console.log("%c[Warning]:", "color:#ef9300;font-weight:bold;");
    console.log(...msg);
  }
}

export default Log;
