import Routers from "core/routers";
import { Icon } from "antd";

class Global {
  public static COMPONENT_UNIQUE_ID_KEY: string = "component_unique_id_key";

  public static BASE_URL?: string = process.env.API_BASE;

  public static PUB_RSA: string =
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDdrUaqYQUpaBLraes/ld3KeCHG\n" +
    "vfMHHFxS6uYFBayyp2hQht0XipslkzqXiMmGKGGeCqDmmKFAhEdVG3NoU0ojEAWn\n" +
    "Dimc23V8W6dpJHmBgml/afk5y/SbK7usQCTXggBmRLt/VO5JBz34KbGPup0jruOZ\n" +
    "QSmryXeWulA2qJfqPQIDAQAB\n" +
    "-----END PUBLIC KEY-----";

  public static routers: Routers;

  public static winWidth: number = (window as any).innerWidth;
  public static winHeight: number = (window as any).innerHeight;
  public static tabBarHeight: number = 50;
  public static navBarHeight: number = 45;

  private static _serverTime: number = 0;
  private static _serverTimeUpdateTime: number = 0;

  public static readonly BMIcon = Icon.createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_1185794_shsg6wnwx1.js"
  });

  public static get contentHeight(): number {
    return Global.winHeight - Global.tabBarHeight - Global.navBarHeight;
  }

  public static get contentHeightWithoutNavBar() {
    return Global.winHeight - Global.tabBarHeight;
  }

  public static get contentHeightWithoutTabBar() {
    return Global.winHeight - Global.navBarHeight;
  }

  public static set serverTime(val: number) {
    console.log("set serverTime: ", val);
    this._serverTime = val;
    this._serverTimeUpdateTime = new Date().getTime();
  }

  public static get serverTime(): number {
    const now = new Date().getTime();
    return this._serverTime + (now - this._serverTimeUpdateTime);
  }
}

export default Global;
